/* eslint-disable @typescript-eslint/dot-notation, @typescript-eslint/ban-types, @typescript-eslint/prefer-for-of */
import {Injectable} from '@angular/core';
import {SharedDotcomConstants} from '@shared/lib/shared-dotcom.constants';
import {TranslationService} from '@usana/ux/translations';
import {TranslationPdfService} from '@usana/ux/translations/pdf';
import {HttpResponse} from '@angular/common/http';
import * as FileSaver from 'file-saver';

@Injectable()
export class TranslationMapperService {
  data;

  constructor(private translationService: TranslationService,
              private translationPdfService: TranslationPdfService) {

  }

  translateFromMap(src: string, map: any): Promise<any> {
    const listOfTags: string[] = this.mapToKeys(src, map);
    return new Promise<any>((resolve, reject) => {
      if (listOfTags.length === 0) {
        resolve({});
      } else {
        this.translationService.translateMultiAsPromise(SharedDotcomConstants.TRANSLATION_NAMESPACE, listOfTags)
          .then((translations: { [tag: string]: string }) => {
            resolve(this.mapData(map, {translations: translations}, listOfTags[0]));
          });
      }
    });
  }

  convertDataFormat(origMap, data) {
    this.data = data; // store data
    const map = JSON.parse(JSON.stringify(origMap)); // we need to copy our orig map so that it isn't manipulated
    // The prefix is so we can get a full translation tag, basically 'filenameMinusDotJson.mapKey'
    const prefix = Object.keys(data['translations'])[0].split('.')[0] + '.';
    // Some maps are arrays rather than objects
    if (Array.isArray(map)) {
      for (let i = 0; i < map.length; i++) {
        for (const item in map[i]) {
          if (map[i].hasOwnProperty(item)) {
            map[i][item] = this.lookupTag(prefix + i + '.' + item);
          }
        }
      }
    } else { // map is an object
      // loop recursively through map object
      for (const item in map) {
        if (map.hasOwnProperty(item)) {
          // for each key in the map, lookup it's key in the data results

          // if the map item is not a string we need to keep looping though to assign values to the map items
          if (typeof map[item] === 'object') {
            // map item is either an Object or an Array
            if (Array.isArray(map[item])) {
              // the map item is an array so we need to keep looping through looking for strings, objects, or more arrays
              for (let i = 0; i < map[item].length; i++) {
                for (const sub in map[item][i]) {
                  if (map[item][i].hasOwnProperty(sub)) {
                    // missing type of
                    // lookup would be this.lookupTag(prefix + map[item][i][sub])
                    if (typeof map[item][i][sub] === 'string') {
                      // the map item is an array strings. loop through it and reassign the map item's value to the corresponding tag value
                      map[item][i][sub] = this.lookupTag(prefix + map[item][i][sub]);
                    } else {
                      // the map item is an array of objects. start to loop again
                      if (Array.isArray(map[item][i][sub])) {
                        for (let x = 0; x < map[item][i][sub].length; x++) {
                          for (const xitem in map[item][i][sub][x]) {
                            // at this point your brain is hurting, but the tags don't go deeper than this.
                            if (map[item][i][sub][x].hasOwnProperty(xitem)) {
                              const value = this.lookupTag(`${prefix}${item}.${i}.${sub}.${x}.${xitem}`);
                              // if the value is undefined there is no such translation tag in the database
                              if (value) {
                                map[item][i][sub][x][xitem] = value;
                              } else {
                                // console.warn('%cIs this a valid tag? %c' + ` ${prefix}${item}.${i}.${sub}.${x}.${xitem} `,
                                // 'color: blue;', 'color: navy;background: tan');
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            } else {
              // map item is an Object, we need to loop through it to assign the matching translation tag's value
              for (const sub in map[item]) {
                if (map[item].hasOwnProperty(sub)) {
                  map[item][sub] = this.lookupTag(prefix + item + '.' + sub);
                }
              }
            }
          } else {
            // map item is a string
            map[item] = this.lookupTag(prefix + item);
          }

          // assign the value in map to the translation
        }
      }
    }

    return map;
  }

  /**
   * Creates a PDF by querying the i18n system.
   */
  createPdf(titleTag: string, bodyTags: string[], verification = true): Promise<Object> {
    return this.translationPdfService.translateAsPdf(
      SharedDotcomConstants.TRANSLATION_NAMESPACE, // The namespace for this service (dotcom)
      titleTag, // The tag which defines the title of the document
      bodyTags, // The tags (ordered) which make up the body of the document
      verification // Whether or not to append a timestamp and hash to the PDF
    ).then((response: HttpResponse<Blob>) => {
      // Attempt to retrieve the filename from the content-disposition header
      const filename = response.headers.get('content-disposition').split('filename=')[1];
      // Generate and save the file
      FileSaver.saveAs(response.body, filename && filename.length > 0 ? filename : 'document.pdf');
      return null;
    });
  }

  lookupTag(tag) {
    if (tag !== this.data['translations'][tag]) {
      return this.data['translations'][tag];
    } else {
      return undefined;
    }
  }

  mapToKeys(filename, map): string[] {
    filename = this.srcToFileNameKey(filename);

    // build a list of tags based off of the supplied map JSON
    const listOfTags = [];
    // take the map and flatten it to make a list of translation keys to fetch
    const flattenedKeys = this.mapValues(this.flattenObject(map));
    for (let i = 0; i < flattenedKeys.length; i++) {
      // since the map doesn't include the namespace + 'filename' (based on the old json filename) we need to prepend those to the list
      listOfTags.push(`${filename}.${flattenedKeys[i]}`);
    }
    return listOfTags;
  }

  srcToFileNameKey(src): string {
    return src; // .split('.')[0];
  }

  private mapData(map, data, singleTag: string): any {
    if (!!map) {
      return this.convertDataFormat(map, data);
    } else {
      return data['translations'][singleTag];
    }
  }

  // take a nested object and collapse it into a single level deep object
  private flattenObject(ob) {
    const toReturn = {};
    for (const i in ob) {
      if (!ob.hasOwnProperty(i)) {
        continue;
      }

      if ((typeof ob[i]) === 'object') {
        const flatObject = this.flattenObject(ob[i]);
        for (const x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) {
            continue;
          }
          toReturn[i + '.' + x] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }
    return toReturn;
  }

  // because I don't want to use a polyfill just for PhantomJS
  private mapValues(map: any): any[] {
    return Object.keys(map).map((e) => map[e]);
  }
}
