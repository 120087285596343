import {InjectionToken} from '@angular/core';
import {DotcomMarket} from '@shared/lib/model/dotcom-market';
import {Locale} from '@shared/lib/model/locale';

// a token wrapper for window.location, this is mostly to help with testing
// but also gives us more of a standard angular injection
export const DOTCOM_LOCATION_TOKEN = new InjectionToken<Location>('dotcom.shared.window-location-obj', {
  factory: () => window.location
});

export const ENVIRONMENT_HOST = new InjectionToken<string>('env.host');
export const ENVIRONMENT_CART_SERVICES_URL = new InjectionToken<string>('env.cartServicesUrl');
export const ENVIRONMENT_SITE_DOMAIN = new InjectionToken<string>('env.siteDomain');
export const ENVIRONMENT_PRODUCTION = new InjectionToken<boolean>('env.production');
export const ENVIRONMENT_SSO_ENABLED = new InjectionToken<boolean>('env.ssoEnabled');

export const SUPPORTED_MARKETS = new InjectionToken<DotcomMarket[]>('supported.markets');

export const SUPPORTED_LOCALES = new InjectionToken<{ [localeKey: string]: Locale }>('locales.supported');
export const DEFAULT_LOCALE = new InjectionToken<string>('locales.default');
