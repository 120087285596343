import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import {MarketService} from '@shared/lib/services/market.service';
import {MarketLanguageSelection} from '@shared/lib/model/market-language-selection';
import {DotcomMarket} from '@shared/lib/model/dotcom-market';

@Component({
  selector: 'u-locale-confirmation',
  templateUrl: './locale-confirmation.component.html',
  styleUrls: ['./locale-confirmation.component.scss']
})
export class LocaleConfirmationComponent implements OnInit {

  @Input()
  locale: string;

  @Output()
  readonly chosenOne: EventEmitter<string> = new EventEmitter<string>();

  selectedMarket: MarketLanguageSelection;
  marketName: string;
  flag: string;
  viewMarkets: boolean;
  markets: DotcomMarket[];

  constructor(private marketService: MarketService) {
    this.viewMarkets = false;
    this.markets = marketService.markets;
  }

  ngOnInit(): void {
    this.updateSelectedMarket(this.locale);
  }

  updateSelectedMarket(localeId: string): void {
    this.selectedMarket = this.marketService.marketFromLocale(localeId);
    if (this.selectedMarket) {
      this.flag = this.selectedMarket.market.flag;
      this.marketName = this.selectedMarket.market.name + ' - ' + this.selectedMarket.getLanguage().language;
    } else {
      this.flag = '';
      this.marketName = '';
    }
  }

  iChooseYou(localeId?: string) {
    if (!localeId) {
      // current
      localeId = this.selectedMarket.getLocale();
    }
    this.chosenOne.emit(localeId);
  }

  toggleMarketList(): void {
    this.viewMarkets = !this.viewMarkets;
  }

}
