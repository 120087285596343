import {Component, OnInit, Inject, ViewChildren, QueryList, ElementRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Meta, Title} from '@angular/platform-browser';
import {LocaleService} from '@shared/lib/services/locale.service';
import {BaseComponent} from '@shared/lib/components/base.component';
import {AuthService} from '@shared/lib/services/auth.service';
import {UrlService} from '@shared/lib/services/url.service';
import {DOCUMENT} from '@angular/common';
import {TranslationMapperService} from '@shared/lib/services/translation-mapper.service';
import {I18nInfo, Market, UsanaMarketService} from '@usana/ux/markets';
import {PageStructureService} from '@shared/lib/services/page-structure.service';
import {DEFAULT_LOCALE} from '@shared/lib/shared.tokens';
import {ComponentTranslationMapping} from '@shared/lib/model/component-translation-mapping';
import {DataService} from '@shared/lib/services/data.service';

@Component({
  selector: 'u-page',
  template: '<ng-content></ng-content>'
})
export class PageComponent extends BaseComponent implements OnInit {
  lang = 'en';
  map = {
    page_title: 'page_title',
    meta_description: 'meta_description',
    meta_keywords: 'meta_keywords'
  };
  pageComponents: any[] = [];

  @ViewChildren(BaseComponent) set setPageComponents(pageComponents: QueryList<BaseComponent>) {
    if (pageComponents) {
      this.pageComponents = [...pageComponents.toArray(), ...this.pageComponents].reduce((arr, item) => {
        const removed = arr.filter(i => i.src !== item.src);
        return [...removed, item];
      }, []);
      this.pageStructureService.setCurrentPageStructure({
        src: this.src,
        map: this.map
      }, this.pageComponents.map<ComponentTranslationMapping>(bc => new ComponentTranslationMapping(bc.src, bc.map)));
    }
  }

  addToPageComponents(event) {
    if (this.pageComponents) {
      this.pageComponents.push(event);
    } else {
      this.pageComponents = [event];
    }
  }

  constructor(@Inject(DEFAULT_LOCALE) protected readonly defaultLocale: string,
              protected usanaMarketService: UsanaMarketService,
              @Inject(DOCUMENT) protected document: Document,
              protected http: HttpClient,
              localeService: LocaleService,
              titleService: Title,
              metaService: Meta,
              protected route: ActivatedRoute,
              protected authService: AuthService,
              urlService: UrlService,
              translationMapperService: TranslationMapperService,
              protected router: Router,
              private pageStructureService: PageStructureService,
              el: ElementRef,
              dataService: DataService) {
    super(http,
      localeService,
      titleService,
      metaService,
      urlService,
      translationMapperService,
      el,
      dataService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.localeService.getLocaleObject().hasPHX) {
      this.authService.getUser(true);
    }
    this.initPageImpression();
  }

  protected defaultComponentData() {
    // just for speed.
    this.data = Object.assign({}, {});
    this.dataIsLoaded = true;
  }

  protected processComponentData(data): void { // NOSONAR
    const host = this.urlService.getHost();

    // need to insert <link hreflang> before the twitter meta
    const twitterCard = this.document.head.querySelector('meta[name=\'twitter:card\']') as HTMLMetaElement;

    // <link rel="canonical" href="https://www.usana.com/ux/dotcom/enu-US/home">
    const canonicalLink = this.document.head.querySelector('link[rel=\'canonical\']') as HTMLLinkElement;
    if (canonicalLink) {
      // the link.href needs to be the full URL without any extra get params
      canonicalLink.href = (host + '/ux/dotcom' + this.urlService.getCurrentRouteUrl()).split('?')[0];
    }
    // <link rel="alternate" href="https://www.usana.com'/ux/dotcom/enu-US/home" hreflang="en-US">
    this.usanaMarketService.geti18NInfoSubject().subscribe((i18nInfo: I18nInfo) => {
      // Remove SEO link entries.
      this.document.querySelectorAll('link[rel="alternate"]').forEach(value => {
        value.remove();
      });
      // set html[lang]  and meta [content-language] of site (alpha2), helps SEO and screen readers
      this.lang = i18nInfo.current.isoMarketId;
      // OneTrust update banner locale switch
      if (this.window.OneTrust) {
        const dotcomMarket = this.lang.toLowerCase();
        this.window.OneTrust.changeLanguage(dotcomMarket);
        // wait a second after we changeLanguage to see if it returned a translation
        setTimeout(() => {
          const networkCapture: PerformanceEntry[] = this.window.performance.getEntriesByType('resource')
            .filter((r: PerformanceResourceTiming) => r.initiatorType === 'fetch');
          const regEx = /.*(consent).*(?<returnedLocale>.{4}\S)\.json/ig;
          const fetchedOneTrustLocales = networkCapture
            .filter(x => x.name.match(regEx)).map(a => a.name.split('/').filter(b => b.includes('.json'))[0].split('.')[0]);
          if (!fetchedOneTrustLocales.includes(dotcomMarket)) {
            // didn't find a language file so fetch the default and end
            this.window.OneTrust.changeLanguage(dotcomMarket.split('-')[0]);
          }
        }, 1000);
      }
      // Loop though our markets.
      i18nInfo.markets.forEach((m: Market) => {
        if (this.localeService.isAllowedMarketId(m.currentMarketId)) {
          const link = `${host}/ux/dotcom/${m.currentMarketId}/home`;

          const element: HTMLLinkElement = this.document.createElement('link');
          element.setAttribute('rel', 'alternate');
          element.setAttribute('href', link);
          element.setAttribute('hreflang', m.isoMarketId);
          if (twitterCard) {
            twitterCard.parentNode.insertBefore(element, twitterCard);
          }

          // US / CN should set with an x-default value.
          if (m.currentMarketId === this.defaultLocale) {
            const xdefault: HTMLLinkElement = this.document.createElement('link');
            xdefault.setAttribute('rel', 'alternate');
            xdefault.setAttribute('href', link);
            xdefault.setAttribute('hreflang', 'x-default');
            if (twitterCard) {
              twitterCard.parentNode.insertBefore(xdefault, twitterCard);
            }
          }
        }
      });
    });

    // change the html lang to the current language of the page; <html lang="en-US">
    this.document.getElementsByTagName('html')[0].setAttribute('lang', this.lang);

    // <meta http-equiv="content-language" content="whatever the current lang-country is">
    this.metaService.updateTag({'http-equiv': 'content-language', content: this.lang}, 'http-equiv="content-language"');

    // <meta property="title"> / description / keywords / og:stuff
    if (data.page_title) {
      this.titleService.setTitle(this.data.page_title);
      this.metaService.updateTag({name: 'title', content: this.data.page_title}, 'name="title"');
      this.metaService.updateTag({property: 'og:title', content: this.data.page_title});
    }
    if (data.meta_description) {
      this.metaService.updateTag({name: 'description', content: this.data.meta_description}, 'name="description"');
      this.metaService.updateTag({property: 'og:description', content: this.data.meta_description});
    }
    if (data.meta_keywords) {
      this.metaService.updateTag({name: 'keywords', content: this.data.meta_keywords}, 'name="keywords"');
    }

    // we only want the Organization Schema on the home page so if it's in the DOM on any other page, remove it.
    if (this.urlService.getCurrentRouteUrl().split('/').pop() === 'home') {
      const organizationSchema = {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        'url': this.urlService.getHost(),
        'name': this.data.page_title,
        'logo': 'https://www.usana.com/ux/dotcom/assets/USANA_logo.svg',
        'alternateName': 'USANA',
        'description': this.data.meta_description
      };

      const script = this.document.getElementById('OrganizationSchema') as HTMLScriptElement ||
        this.document.head.appendChild(this.document.createElement('script'));
      script.type = 'application/ld+json';
      script.id = 'OrganizationSchema';
      script.text = JSON.stringify(organizationSchema);
    } else {
      const orgEl = this.document.getElementById('OrganizationSchema') as HTMLScriptElement;
      if (orgEl) {
        orgEl.parentNode.removeChild(orgEl);
      }
    }

  }

  // don't add the impression to the GTM dataLayer yet until we get some ads loaded, so for now create a bucket to push latter
  initPageImpression() {
    const obj = {
      event: 'EEpromotionView',
      ecommerce: {
        promoView: {
          promotions: []
        }
      }
    };
    this.window.impressionBucket = this.window.impressionBucket || [];
    this.window.impressionBucket.push(obj);
  }

  // call this explicitly in the individual page components where you want
  makeImpression() {
    if (this.window.impressionBucket[this.window.impressionBucket.length - 1].ecommerce.promoView.promotions.length > 0) {
      this.window.dataLayer.push(...this.window.impressionBucket);
      // if the floating presence data layer exists lets push to that as well.
      if (this.window.fpDataLayer) {
        this.window.fpDataLayer.push(...this.window.impressionBucket);
      }
    }
  }

}
