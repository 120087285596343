import {Injectable} from '@angular/core';
import {SharedDotcomConstants} from '@shared/lib/shared-dotcom.constants';
import {CookieService} from 'ngx-cookie-service';

@Injectable()
export class DataService {

  private isPrerender: boolean;
  private readonly hasEagerCookie: boolean;

  constructor(cookieService: CookieService) {
    this.hasEagerCookie = cookieService.get(SharedDotcomConstants.EAGER_RENDER_COOKIE) != null;
  }

  init(): void {
    this.isPrerender = SharedDotcomConstants.PRERENDER_USER_AGENT.test(navigator.userAgent);
  }

  isFancyMode(): boolean {
    return !this.isPrerender;
  }

  isEagerRendering(): boolean {
    return this.hasEagerCookie;
  }
}
