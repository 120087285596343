import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDotcomConstants } from '@shared/lib/shared-dotcom.constants';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';
import { UrlService } from './url.service';

import { registerLocaleData } from '@angular/common';
import { Locale } from '@shared/lib/model/locale';
import { DEFAULT_LOCALE, ENVIRONMENT_HOST, SUPPORTED_LOCALES } from '@shared/lib/shared.tokens';
import { LOCATION_TOKEN } from '@usana/ux/common';
import { UsanaLoggingService } from '@usana/ux/common/logging';
import { I18nInfo, Market, UsanaMarketService } from '@usana/ux/markets';
import { TranslationService } from '@usana/ux/translations';

@Injectable()
export class LocaleService {
  private locale = '';
  private localeSubject: Subject<string>;

  constructor(
    private cookieService: CookieService,
    private urlService: UrlService,
    private router: Router,
    private usanaMarketService: UsanaMarketService,
    private translationService: TranslationService,
    @Inject(SUPPORTED_LOCALES) private readonly locales: { [localeKey: string]: Locale },
    @Inject(DEFAULT_LOCALE) private readonly defaultLocale: string,
    @Inject(ENVIRONMENT_HOST) private readonly environmentHost: string,
    @Inject(LOCATION_TOKEN) private readonly location: Location
  ) {
    // a lot of this logic can be removed once we move away from the hard coded locale objects (see have a cms)
    // that includes this service, the supported_locales, the supported_markets, etc.
    this.localeSubject = new Subject<string>();
    this.usanaMarketService.geti18NInfoSubject().subscribe((i18nInfo: I18nInfo) => {
      if (!!i18nInfo.current.currentMarketId && i18nInfo.current.currentMarketId !== this.locale) {
        UsanaLoggingService.logFn(() => `[LocaleService] got ${JSON.stringify(i18nInfo.current)}`, true);
        if (this.isAllowedMarketId(i18nInfo.current.currentMarketId)) {
          // todo this should move into the lib at some point (when we don't have a hard list in this app)
          registerLocaleData(i18nInfo.current.isoMarketId);
          // we still have to keep this variable while stuff is hard coded
          this.locale = i18nInfo.current.currentMarketId;

          // eventually the service should listen itself
          this.translationService.setMarketId(this.locale);

          setTimeout(() => {
            this.forcePushLocaleSubject();
          }, 300);
        } else {
          this.bootEmMarket(i18nInfo.current);
        }
      }
    });
  }

  getLocale(): string {
    return this.locale;
  }

  getEmptyLocaleObject(): Locale {
    return {
      key: undefined,
      warehouse: undefined,
      hasPHX: false,
      hasOlapic: false,
      hasIncelligence: true,
      hasHybridTechnology: true,
      hasOlivol: true,
      hasScienceDisclaimer: false,
      hasChampions: true,
      hasDrLee: true,
      hasEnergy: true,
      hasWeightManagement: true,
      hasBlog: true,
      hasAtsSearch: false,
      hasRetailShopping: false,
    };
  }

  getLocaleObject(): Locale {
    return this.getLocaleObjectOf(this.getLocale());
  }

  getLocaleObjectOf(key: string): Locale {
    const loc = this.locales[key];
    if (!!loc) {
      return loc;
    }
    return this.getEmptyLocaleObject();
  }

  /**
   * use caution while doing this.
   */
  forcePushLocaleSubject(): void {
    this.localeSubject.next(this.locale);
  }

  getLocaleObservable(): Observable<string> {
    return this.localeSubject.asObservable();
  }

  isAllowedMarketId(marketId: string): boolean {
    return !!this.locales[marketId] && this.locales[marketId].key === marketId;
  }

  bootEmMarket(market: Market): string {
    if (!!this.locales[market.currentMarketId]) {
      this.location.replace(this.environmentHost + '/dotCom/redirect?SET_LOCALE=' + market.portalMarketId);
      return null;
    } else {
      this.router.navigateByUrl('/' + this.defaultLocale + '/home');
      return this.defaultLocale;
    }
  }

  isBaoyingMarket(): boolean {
    return this.usanaMarketService.isBaoying();
  }

  hasConfirmedLocaleCookie(): boolean {
    // only one locale for baoying so lets ignore
    if (this.isBaoyingMarket()) {
      return true;
    }
    const hasCookie = !!this.cookieService.get(SharedDotcomConstants.CONFIRMED_LOCALE_COOKIE);
    // if we have the cookie then we are going to bump the cookie
    if (hasCookie) {
      this.bumpConfirmedLocaleCookie();
    }
    return hasCookie;
  }

  getLocaleList(): { [localeKey: string]: Locale } {
    return this.locales;
  }

  bumpConfirmedLocaleCookie(): void {
    const cd: Date = new Date();
    // 30 days in the future!
    cd.setTime(cd.getTime() + 30 * 24 * 60 * 60 * 1000);
    this.cookieService.set(
      SharedDotcomConstants.CONFIRMED_LOCALE_COOKIE,
      'y',
      cd,
      '/',
      this.urlService.getDomain(),
      false,
      'Lax'
    );
  }
}
