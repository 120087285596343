import { Component } from '@angular/core';
import { BaseComponent } from '@shared/lib/components/base.component';

@Component({
  selector: 'u-ccpa',
  templateUrl: './ccpa.component.html',
  styleUrls: ['./ccpa.component.scss'],
  // nothing to deep link so no need to provide
  // providers: [{provide: BaseComponent, useExisting: CcpaComponent}]
})
export class CcpaComponent extends BaseComponent {

}
