/* eslint-disable @typescript-eslint/prefer-for-of, @typescript-eslint/dot-notation */
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { faEnvelope, faMobile } from '@fortawesome/free-solid-svg-icons';
import { BaseComponent } from '@shared/lib/components/base.component';
import { FloatingPresence } from '@shared/lib/model/floating-presence';
import { DataService } from '@shared/lib/services/data.service';
import { FloatingPresenceService } from '@shared/lib/services/floating-presence.service';
import { LocaleService } from '@shared/lib/services/locale.service';
import { TranslationMapperService } from '@shared/lib/services/translation-mapper.service';
import { UrlService } from '@shared/lib/services/url.service';

@Component({
  selector: 'associate-floating-presence',
  templateUrl: './floating-presence.component.html',
  styleUrls: ['./floating-presence.component.scss'],
})
export class FloatingPresenceComponent extends BaseComponent {
  floatingPresence: FloatingPresence;
  featuredProducts: any;
  stateClass = 'min';
  showPricing = false; // TODO: currently will only show for markets with retail shopping
  showRetailComparison = true; // TODO:
  showPcComparison = true; // TODO:
  displayFavorites = false;
  gtmUrl = 'https://www.googletagmanager.com/gtm.js?id=';

  MOBILE_ICON = faMobile;
  EMAIL_ICON = faEnvelope;

  tags = {
    heading: 'my-usana.story-heading',
    myStory: 'my-usana.story-default',
    enrollmentText: 'my-usana.enrollment-text',
  };

  constructor(
    private floatingPresenceService: FloatingPresenceService,
    protected http: HttpClient,
    localeService: LocaleService,
    titleService: Title,
    metaService: Meta,
    urlService: UrlService,
    translationMapperService: TranslationMapperService,
    el: ElementRef,
    private router: Router,
    dataService: DataService
  ) {
    super(http, localeService, titleService, metaService, urlService, translationMapperService, el, dataService);
    router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.stateClass = 'min';
      }
    });
  }

  localeChangeBeforeLoadContent(locale) {
    this.showPricing = this.localeService.getLocaleObject().hasRetailShopping;
    this.floatingPresenceService.loadFloatingPresence().then((fp: FloatingPresence) => {
      this.floatingPresence = fp;
      if (fp.country === 'IN') {
        // india has different content
        this.tags = {
          heading: 'my-usana.story-heading.IN',
          myStory: 'my-usana.story-default.IN',
          enrollmentText: 'my-usana.enrollment-text.IN',
        };
      }
      if (fp.params) {
        this.setMyStory(fp.params);

        // if they have and are showing "Favorite" products
        if (fp.params['favorites'].value !== null && fp.params['favorites'].visible) {
          this.displayFavorites = true;

          this.loadProducts(fp.params);
        } else {
          this.displayFavorites = false;
          this.floatingPresenceService.loadFeaturedProducts().then((featured: any) => {
            this.featuredProducts = featured;
          });
        }
        if (this.floatingPresence.params['gtm'].value) {
          this.loadGtmScript(this.floatingPresence.params['gtm'].value);
        }
      }
    });
  }

  setMyStory(params): void {
    // If there is a value for my story language assign it to my story field
    const currentLanguage =
      this.localeService.usanaMarketService.usanaInternalMarketService.i18nInfo.current.currentMarketId.split('-')[0];

    const mystories = [];
    for (let i = 0; i < Object.keys(params).length; i++) {
      if (Object.keys(params)[i].indexOf('mystory_') > -1) {
        mystories.push(params[Object.keys(params)[i]]);
      }
    }

    if (
      params['mystory_' + currentLanguage] &&
      params['mystory_' + currentLanguage].value !== null &&
      mystories.filter((r) => r.visible === true).length > 0
    ) {
      this.floatingPresence.params['mystory'].value = params['mystory_' + currentLanguage].value;
    } else {
      // use default my story if not set and visible
      this.floatingPresence.params['mystory'].value = null;
    }
  }

  loadProducts(params): void {
    this.featuredProducts = [];
    const favoritesList = params['favorites'].value.split(',');

    for (let i = 0; i < favoritesList.length; i++) {
      const usanaId = favoritesList[i];
      this.floatingPresenceService.loadProduct(usanaId).then((favorite: any) => {
        // Since we don't really know the key, we need to iterate through this object and pull the object out of it.
        if (favorite) {
          for (const key in favorite.products) {
            if (favorite.products[key].usanaId.split('.')[0] === usanaId.split('.')[0]) {
              this.featuredProducts.push(favorite.products[key]);
            }
          }
          // clean up to ensure proper image based on order and size are prioritized in results
          this.featuredProducts = this.productImagesSortedByImageOrder(this.featuredProducts);
        }
      });
    }
  }

  productImagesSortedByImageOrder(products) {
    return products.map((product) => {
      product.productImages.sort((a, b) => a.imageOrder - b.imageOrder);
      product.productImages = product.productImages.filter((image) => image.type.imageSize === 'XS');
      return product;
    });
  }

  removeDuplicateProducts(products) {
    return products.filter((v, i, a) => a.findIndex((t) => t.usanaId.split('.')[0] === v.usanaId.split('.')[0]) === i);
  }

  loadGtmScript(gtmId) {
    const node = document.createElement('script');
    node.type = 'text/javascript';
    node.id = 'pfpGtm';
    node.appendChild(
      document.createTextNode(`window.fpDataLayer = window.fpDataLayer || [];
    fpDataLayer.push({
      'gtm.blocklist': ['html']
    });
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push(
      {'gtm.start': new Date().getTime(),event:'gtm.js'}
    );var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      '${this.gtmUrl}'+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','fpDataLayer', '${gtmId.trim()}');`)
    );
    if (!document.getElementById('pfpGtm')) {
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  show(): boolean {
    return this.precenseIsLoaded();
  }

  enrollLink(): string {
    return this.floatingPresenceService.getEnrollLink();
  }

  private precenseIsLoaded(): boolean {
    return !!this.floatingPresence && !!this.floatingPresence.websiteId;
  }

  toggle() {
    this.stateClass = this.stateClass.includes('max') ? '' : 'max';
  }

  goToProduct(productId) {
    const phxMarketId = this.localeService.usanaMarketService.usanaInternalMarketService.i18nInfo.current.phxMarketId;
    window.open(this.urlService.getProductUrl(productId, phxMarketId), '_blank');
  }

  /* borrowed from pwphoenix */
  getProductCounts(products) {
    const counts = {};

    for (let i = 0; i < products.length; i++) {
      if (!counts[products[i]]) {
        counts[products[i]] = 1;
      } else {
        counts[products[i]]++;
      }
    }

    return counts;
  }

  /* borrowed from pwphoenix */
  showPriceByProduct(product): string {
    if (!product) {
      return;
    }
    return product.price.priceDisplay;
  }

  /* borrowed from pwphoenix */
  showPcComparisonByProduct(product): boolean {
    return (
      !!product &&
      !!product.pcPrice &&
      this.showPcComparison &&
      product.pcPrice.priceDisplay != product.price.priceDisplay
    ); // eslint-disable-line
  }

  /* borrowed from pwphoenix */
  showRetailStrikethroughByProduct(product): boolean {
    return (
      !!product &&
      !!product.retailPrice &&
      this.showRetailComparison &&
      product.retailPrice.priceDisplay != product.price.priceDisplay
    ); // eslint-disable-line
  }

  /* borrowed from pwphoenix */
  addAllToCart() {
    const products = this.featuredProducts.map((f) => f.usanaId); // TODO: list of usanaIds for products.
    const sponsorId = ''; // TODO: need to get sponsorId somewhere com.obs.webHosting.WEBHOST_OWNER_COOKIE
    const counts = this.getProductCounts(products);
    let url = '/ux/cart/#!/share?cartInit={"shareListProducts":[';

    for (let iPhx = 0; iPhx < products.length; iPhx++) {
      if (!!counts[products[iPhx]]) {
        url += '{"usanaId":' + products[iPhx] + ',"quantity":' + counts[products[iPhx]] + '},';
        counts[products[iPhx]] = undefined; // set to undefined so we know we have used it
      }
    }
    // pull off the last comma outside the loop, takes care of the edge cases from the counts[products[]] array
    url = url.substring(0, url.length - 1);

    url += ']}&shopperSource=PHX-PWP&sponsorId=' + sponsorId;
    // TODO will this work on mobile?
    window.open(url, '_blank');
  }
}
