import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { ApolloQueryResult } from '@apollo/client/core';
import { FormInputData } from '@usana/ux/universal-components/input/base';
import { AssociateSearchResult, AssociateSearchResultEntry } from '../../../models/associate-search-result';

@Component({
  selector: 'u-dc-u-associate-search',
  templateUrl: './associate-search.component.html',
  styleUrls: ['./associate-search.component.scss'],
})
export class AssociateSearchComponent {
  @Input() searchResult: ApolloQueryResult<AssociateSearchResult>;

  @Output() readonly associateSearchClicked = new EventEmitter<AssociateSearchArgs>();
  @Output() readonly closeClicked = new EventEmitter<AssociateSearchArgs>();
  @Output() readonly backClicked = new EventEmitter<AssociateSearchArgs>();

  readonly DELISTED_CLASSIFICATION = 'Delisted';

  associateSearchForm = this.formBuilder.group(
    {
      id: [''],
      firstName: [''],
      lastName: [''],
    },
    {
      validators: [this.validateAssociateSearchInput],
    }
  );

  associateFirstNameData: FormInputData = {
    id: 'associate-first-name-text',
    name: 'associate-first-name-text',
    value: '',
    forcedState: undefined,
  };

  associateLastNameData: FormInputData = {
    id: 'associate-last-name-text',
    name: 'associate-last-name-text',
    value: '',
    forcedState: undefined,
  };

  associateSearchIdData: FormInputData = {
    id: 'associate-search-id-text',
    name: 'associate-search-id-text',
    value: '',
    forcedState: undefined,
  };

  previousSearchTerm: string;

  constructor(private readonly formBuilder: FormBuilder) {}

  onAssociateSearch(): void {
    this.previousSearchTerm = this.customerId || `${this.associateFirstName} ${this.associateLastName}`;

    this.associateSearchClicked.emit({
      customerId: this.customerId,
      firstName: this.associateFirstName,
      lastName: this.associateLastName,
    });
  }

  get customerId(): string {
    return this.associateSearchForm.controls.id.value;
  }

  get associateFirstName(): string {
    return this.associateSearchForm.controls.firstName.value;
  }

  get associateLastName(): string {
    return this.associateSearchForm.controls.lastName.value;
  }

  get searchResults(): AssociateSearchResultEntry[] {
    return this.searchResult?.data?.getAssociateSearch?.results;
  }

  onCloseClicked(): void {
    this.closeClicked.emit();
  }

  onBackClicked(): void {
    this.backClicked.emit();
  }

  validateAssociateSearchInput(form: FormGroup): ValidationErrors | null {
    let error: ValidationErrors | null = null;

    const hasCustomerId = !!form.controls.id.value;
    const hasFullName = !!form.controls.firstName.value && !!form.controls.lastName.value;

    if (!hasCustomerId && !hasFullName) {
      error = { noSearchCriteria: true };
    }

    return error;
  }
}

export interface AssociateSearchArgs {
  customerId: string;
  firstName: string;
  lastName: string;
}
