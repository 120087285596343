import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { UsanaTranslationsModule } from '@usana/ux/translations';
import { UniversalButtonModule } from '@usana/ux/universal-components/button';
import { UniversalFormInputTextModule } from '@usana/ux/universal-components/input/text';
import { AssociateSearchModalComponent } from './components/associate-search-modal/associate-search-modal.component';
import { AssociateSearchComponent } from './components/associate-search-modal/associate-search/associate-search.component';

@NgModule({
  declarations: [AssociateSearchComponent, AssociateSearchModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UniversalFormInputTextModule,
    UniversalButtonModule,
    UsanaTranslationsModule,
    NgbModalModule,
  ],
})
export class AssociateSearchModule {}
