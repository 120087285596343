const dep = {
  siteDomain: '[___ key "global/deploy_SiteDomain" ___]',
};

export const environment = {
  production: true,
  host: '../..',
  cartService: `https://esb.${dep.siteDomain}/cart`,
  i18nService: `https://api.${dep.siteDomain}/i18n`,
  gatewayUrl: `https://api.${dep.siteDomain}/dotcom-gateway/`,
  siteDomain: dep.siteDomain,
  loginRealm: 'usana',
  ssoEnabled: true,
  recaptchaEnabled: true,
  recaptchaV3SiteKey: '6Ldm_IkhAAAAAMpVYA6jxytxTaGUbyc3fC98Axd4',
};
