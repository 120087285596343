import {Component, OnInit, OnDestroy, Inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LocaleService} from '@shared/lib/services/locale.service';
import {Title} from '@angular/platform-browser';
import {UrlService} from '@shared/lib/services/url.service';
import {AuthService} from '@shared/lib/services/auth.service';
import {UsanaMarketService, I18nInfo} from '@usana/ux/markets';
import {Subscription} from 'rxjs';
import {ENVIRONMENT_PRODUCTION} from '@shared/lib/shared.tokens';
import {Locale} from '@shared/lib/model/locale';

@Component({
  selector: 'u-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
})
export class SearchBoxComponent implements OnInit, OnDestroy {
  host: string;
  src = '';
  data;
  noResults: boolean;
  searchStr: string;
  searchLoading: boolean;
  searchLocale: Locale;
  phxMarketId: string;

  marketSubscription: Subscription;

  constructor(
    private http: HttpClient,
    private localeService: LocaleService,
    titleService: Title,
    private urlService: UrlService,
    private authService: AuthService,
    private usanaMarketService: UsanaMarketService,
    @Inject(ENVIRONMENT_PRODUCTION) private readonly production: boolean
  ) {
    this.searchLocale = this.localeService.getEmptyLocaleObject();
    this.host = urlService.getCartServiceUrl();
    this.searchLoading = false;
    this.noResults = false;
  }

  ngOnInit(): void {
    this.marketSubscription = this.usanaMarketService
      .geti18NInfoSubject()
      .subscribe((i18nInfo: I18nInfo) => {
        if (!!i18nInfo.current.currentMarketId) {
          this.phxMarketId = i18nInfo.current.phxMarketId;
          this.setupSearchLocale(
            this.localeService.getLocaleObjectOf(
              i18nInfo.current.currentMarketId
            )
          );
        }
      });
  }

  ngOnDestroy(): void {
    if (this.marketSubscription) {
      this.marketSubscription.unsubscribe();
      delete this.marketSubscription;
    }
  }

  private setupSearchLocale(locale: Locale): void {
    this.searchLocale = locale;
    this.src = `${this.host}/product/quickSearch?resultLimit=40&warehouse=${this.searchLocale.warehouse}&searchString=`;
    this.clearSearch();
  }

  showSearch(): boolean {
    return (
      (this.searchLocale.hasPHX &&
        this.authService.isSetup() &&
        !!this.phxMarketId) ||
      !this.production
    );
  }

  clearSearch() {
    this.data = [];
    this.noResults = false;
    this.searchStr = undefined;
    this.searchLoading = false;
  }

  search() {
    const theSearch = this.searchStr;
    if (!!this.searchStr && this.searchStr.trim() !== '') {
      this.searchLoading = true;
      this.noResults = false;
      this.http.get(this.src + this.searchStr).subscribe((data: any) => {
        if (this.searchStr === theSearch) {
          this.data = this.productImagesSortedByImageOrder(data); // eslint-disable-line
          this.searchLoading = false;
          if (this.data.length < 1) {
            this.noResults = true;
          }
        }
      });
    } else {
      this.clearSearch();
    }
  }

  productImagesSortedByImageOrder(products) {
    return products.map((product) => {
      product.productImages.sort((a, b) => a.imageOrder - b.imageOrder);
      product.productImages = product.productImages.filter(
        (image) => image.type.imageSize === 'XS'
      );
      return product;
    });
  }

  getProductUrl(usanaId: string): string {
    return this.urlService.getProductUrl(usanaId, this.phxMarketId);
  }
}
