<div *ngIf="dataIsLoaded && !marketIn('zhs-CN')">
  <div class="logo-container">
    <img
      class="img-fluid"
      src="assets/USANA_logo.svg"
      alt="{{ 'footer.usanaLogo.text' | i18n : 'usanadotcom' }}"
      height="132"
      width="150"
    />
  </div>
  <div class="container">
    <div class="row footer-links">
      <div
        *ngFor="let item of data.items; index as i"
        class="footer-link-column col-md-3"
        [class.col-md-4]="marketIn('enu-IN')"
      >
        <div class="column-title" (click)="isCollapsed[i] = !isCollapsed[i]" tabindex="0">
          {{ item.name }} <fa-icon [icon]="ANGLE_DOWN_ICON"></fa-icon>
        </div>
        <div [(ngbCollapse)]="isCollapsed[i]">
          <div *ngFor="let child of item.children" class="children">
            <a
              *ngIf="hasInnerText(child.name | trim)"
              [attr.data-automation]="urlPathId(child.name)"
              [href]="child.url | safe"
              [target]="child.target === '_blank' ? '_blank' : '_self'"
              (click)="onFooterLinkClicked(child)"
            >
              {{ child.name }}
            </a>
          </div>
        </div>
      </div>
      <div *ngIf="!marketIn('enu-IN')" class="footer-link-column col-md-3">
        <div class="column-title">{{ data.followText }}</div>

        <ng-container *ngFor="let social of data.socialMedia; index as i">
          <a
            *ngIf="social.social | trim"
            href="{{ social.url }}"
            class="social-media-footer-link"
            target="_blank"
            rel="noopener"
            [attr.aria-label]="social.social"
            [attr.data-automation]="social.social"
          >
            <fa-icon [icon]="['fab', social.social]" class="fa-lg"></fa-icon>
          </a>
        </ng-container>

        <a
          class="logo-link"
          attr.data-automation="{{ urlPathId('footer.logoLink.0.url' | i18n : 'usanadotcom') }}"
          href="{{ 'footer.logoLink.0.url' | i18n : 'usanadotcom' }}"
          target="_blank"
          rel="noopener"
        >
          <img
            src="{{ 'footer.logoLink.0.image' | i18n : 'usanadotcom' }}"
            alt="{{ 'footer.logoLink.0.text' | i18n : 'usanadotcom' }}"
          />
        </a>

        <ng-container *ngIf="data.socialMediaLocale[0].social">
          <br />
          <br />
          <div class="column-title" [innerHTML]="data.followTextLocale"></div>
          <ng-container *ngFor="let social of data.socialMediaLocale; index as i">
            <a
              *ngIf="hasInnerText(social.url)"
              attr.data-automation="{{ urlPathId(social.url) }}"
              href="{{ social.url }}"
              class="social-media-footer-link"
              target="_blank"
              rel="noopener"
              [attr.aria-label]="social.social"
            >
              <fa-icon [icon]="['fab', social.social]" class="fa-lg"></fa-icon>
            </a>
          </ng-container>
        </ng-container>
        <div class="footer-extra" [innerHTML]="data.footerExtra"></div>
      </div>
    </div>

    <div *ngIf="marketIn('enu-MY,zhs-MY,msa-MY')" class="row address-container">
      <p class="col-12 col-lg-6">
        {{ 'footer.registration.title' | i18n : 'usanadotcom' }}<br />
        {{ 'footer.registration.number' | i18n : 'usanadotcom' }}
      </p>
    </div>

    <div *ngIf="marketIn('enu-IN')" class="row address-container">
      <p class="col-12 col-lg-6">
        {{ 'footer.address.line1' | i18n : 'usanadotcom' }}<br />
        {{ 'footer.address.line2' | i18n : 'usanadotcom' }}<br />
        {{ 'footer.address.line3' | i18n : 'usanadotcom' }}
      </p>

      <p class="col-12 col-lg-6">
        <a
          *ngIf="'footer.contact.email' | i18n : 'usanadotcom' | trim as footerEmail"
          target="_blank"
          rel="noopener"
          href="mailto:{{ footerEmail }}"
          data-automation="footer-email"
        >
          <fa-icon [icon]="ENVELOPE_ICON"></fa-icon>{{ footerEmail }}
        </a>

        <br />

        <a
          *ngIf="'footer.contact.phone' | i18n : 'usanadotcom' | trim as footerPhone"
          target="_blank"
          rel="noopener"
          href="tel:+{{ footerPhone }}"
        >
          <fa-icon [icon]="PHONE_ICON"></fa-icon>{{ footerPhone }}
        </a>
      </p>
    </div>

    <div class="text-start cookie-button-container">
      <a
        class="optanon-toggle-display cookie-settings-policy"
        [innerHTML]="data.cookiesettings"
        data-automation="cookiesettings"
      ></a>
    </div>

    <div class="text-start" *ngIf="marketIn('enu-US,spa-US')"><u-ccpa></u-ccpa></div>

    <div class="disclaimer">
      <div [innerHTML]="data.disclaimer1"></div>
      <div [innerHTML]="data.disclaimer2"></div>
      <div [innerHTML]="data.disclaimer3"></div>
      <div [innerHTML]="data.disclaimer4"></div>
      <div [innerHTML]="data.disclaimer5"></div>
      <div *ngFor="let legal of data.legal; index as i">
        <a
          *ngIf="hasInnerText(legal.name)"
          attr.data-automation="{{ urlPathId(legal.url) }}"
          href="{{ legal.url }}"
          class="legal-link"
          target="{{ legal.target }}"
        >
          {{ legal.name }}
        </a>
      </div>

      <div
        [innerHTML]="data.adaPolicy"
        [ngClass]="{ 'ada-policy': !!data.adaPolicy }"
        data-automation="adaPolicy"
      ></div>
      <div [innerHTML]="data.copyright"></div>
    </div>
  </div>
</div>
