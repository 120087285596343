import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocaleService } from '@shared/lib/services/locale.service';
import { DataService } from '@shared/lib/services/data.service';
import { Market, UsanaMarketService } from '@usana/ux/markets';

@Component({
  selector: 'u-dm',
  template:
    '<router-outlet></router-outlet>' +
    '<u-locale-confirmation *ngIf="showMarketConfirmation" ' +
    '[locale]="resolvedLocale" ' +
    '(chosenOne)="youWereTheChosenLocale($event)"></u-locale-confirmation>',
})
export class DetermineMarketComponent implements OnInit {
  showMarketConfirmation: boolean;
  resolvedLocale: string;

  constructor(
    private route: ActivatedRoute,
    private usanaMarketService: UsanaMarketService,
    private localeService: LocaleService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.dataService.init(); // this determines if we are prerender
    this.route.data.subscribe((data) => {
      // eslint-disable-next-line
      this.resolvedLocale = (data['market'] as Market).currentMarketId;
      // check if they have confirmed, and we are fancy mode (not prerender)
      this.showMarketConfirmation = this.dataService.isFancyMode() && !this.localeService.hasConfirmedLocaleCookie();
    });
  }

  youWereTheChosenLocale(chosenLocale: string): void {
    // conform
    this.showMarketConfirmation = false;
    this.localeService.bumpConfirmedLocaleCookie();

    if (chosenLocale !== this.resolvedLocale) {
      // bring balance by switching locales
      this.usanaMarketService.setMarketId(chosenLocale);
    }
  }
}
