import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faFacebook,
  faInstagram,
  faPinterest,
  faTwitter,
  faWordpress,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

export const USANA_FONTAWESOME_ICONS = [faFacebook, faInstagram, faWordpress, faYoutube, faTwitter, faPinterest];

@NgModule({
  imports: [CommonModule, FontAwesomeModule],
  exports: [FontAwesomeModule],
})
export class UsanaFontAwesomeModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(...USANA_FONTAWESOME_ICONS);
  }
}
