import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SafePipe} from '@shared/lib/pipes/safe.pipe';
import {SafeHtmlPipe} from '@shared/lib/pipes/safeHtml.pipe';

@NgModule({
  declarations: [
    SafePipe,
    SafeHtmlPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SafePipe,
    SafeHtmlPipe
  ]
})
export class SafePipesModule {
}
