import {Routes, Route} from '@angular/router';
import {DetermineMarketComponent} from '@shared/lib/components/determine-market.component';
import {MarketResolver} from '@usana/ux/markets/routing';
import {PageNotFoundComponent} from '@shared/lib/modules/page-not-found/components/page-not-found.component';

const SHARED_ROUTES: Route[] = [

  {
    path: 'opportunity',
    loadChildren: () => import('@shared/lib/lazy-modules/opportunity/opportunity.module').then(m => m.OpportunityModule),
    data: {
      preload: true
    }
  },
  {
    path: 'story',
    loadChildren: () => import('@shared/lib/lazy-modules/story/story.module').then(m => m.StoryModule),
    data: {
      preload: true
    }
  },
  {
    path: 'news',
    loadChildren: () => import('@shared/lib/lazy-modules/news/news.module').then(m => m.NewsModule)
  },
  {
    path: 'dmca',
    loadChildren: () => import('@shared/lib/lazy-modules/legal/dmca/dmca.module').then(m => m.DmcaModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('@shared/lib/lazy-modules/legal/terms/terms.module').then(m => m.TermsModule)
  },
  {
    path: 'terms-policies',
    loadChildren: () => import('@shared/lib/lazy-modules/legal/policies/policies.module').then(m => m.PoliciesModule)
  },
  {
    path: 'ctsca',
    loadChildren: () => import('@shared/lib/lazy-modules/legal/ctsca/ctsca.module').then(m => m.CtscaModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('@shared/lib/lazy-modules/legal/privacy/privacy.module').then(m => m.PrivacyModule)
  },
  {
    path: 'income',
    loadChildren: () => import('@shared/lib/lazy-modules/income/income.module').then(m => m.IncomeModule)
  },
  {
    path: 'gdpr',
    loadChildren: () => import('@shared/lib/lazy-modules/legal/gdpr/gdpr.module').then(m => m.GdprModule)
  },
];

const SHARED_FINAL_ROUTES: Route[] = [
  // make sure these 2 are the last 2
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

// eslint-disable-next-line
export function combineAppAndSharedRoutes(appRoutes: Routes, lastRoutes: Routes): Routes {
  return [
    {
      path: ':marketId',
      component: DetermineMarketComponent,
      resolve: {
        market: MarketResolver
      },
      // its important that the appRoutes go first since we have the '' and ** care in the shared_routes
      children: appRoutes.concat(SHARED_ROUTES, lastRoutes, SHARED_FINAL_ROUTES)
    },
    {
      path: '',
      redirectTo: '/home',
      pathMatch: 'full'
    }
  ];
}
