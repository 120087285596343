import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';
import {UsanaModule} from './app/usana.module';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(UsanaModule)
  .catch(err => console.log(err));
