<div>
  <h5>Translations</h5>
  <div>
    <button (click)="forceRefresh(false)">refresh changed</button><button (click)="forceRefresh(true)">force refresh</button>
  </div>
  <div>
    <button (click)="goToHeader()">Edit Header</button>
    <button (click)="goToPageTags()">Edit Page SEO translations</button>
    <button (click)="goToFooter()">Edit Footer</button>
  </div>
  <div>
    <label>Edit Item</label>
    <select [(ngModel)]="selectedPageItem">
      <option *ngFor="let c of pageStructure.components" [ngValue]="c">{{c.src}}</option>
    </select>
    <button (click)="goToSelectedItem()" [disabled]="!selectedPageItem">Edit Item</button>
  </div>
</div>
