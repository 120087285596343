/* eslint-disable @typescript-eslint/dot-notation */
import { Component, ElementRef, Inject } from '@angular/core';
import { BaseComponent } from '@shared/lib/components/base.component';
import { HttpClient } from '@angular/common/http';
import { LocaleService } from '@shared/lib/services/locale.service';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { UrlService } from '@shared/lib/services/url.service';
import { TranslationMapperService } from '@shared/lib/services/translation-mapper.service';
import { PageStructureService } from '@shared/lib/services/page-structure.service';
import { DataService } from '@shared/lib/services/data.service';

@Component({
  selector: 'u-menu-desktop',
  templateUrl: './menu-desktop.component.html',
  styleUrls: ['./menu-desktop.component.scss'],
})
export class MenuDesktopComponent extends BaseComponent {
  src = 'menu';
  map = [
    {
      url: '0.url',
      name: '0.name',
      target: '0.target',
    },
    {
      url: '1.url',
      name: '1.name',
      target: '1.target',
    },
    {
      url: '2.url',
      name: '2.name',
      target: '2.target',
    },
    {
      url: '3.url',
      name: '3.name',
      target: '3.target',
    },
    {
      url: '4.url',
      name: '4.name',
      target: '4.target',
    },
    {
      url: '5.url',
      name: '5.name',
      target: '5.target',
    },
  ];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    protected http: HttpClient,
    localeService: LocaleService,
    titleService: Title,
    metaService: Meta,
    urlService: UrlService,
    translationMapperService: TranslationMapperService,
    pageStructureService: PageStructureService,
    el: ElementRef,
    dataService: DataService
  ) {
    super(http, localeService, titleService, metaService, urlService, translationMapperService, el, dataService);
    // note we only register in the desktop version so that we don't double register
    // since there is a mobile and desktop version and they are both included always and then hidden via css.
    pageStructureService.setHeaderStructure({
      src: this.src,
      map: this.map,
    });
  }

  handleLink(event: any, url: string, target: string): void {
    this.urlService.handleAnchorEvent(event, url, target);
  }

  isActiveLink(item: any): boolean {
    // just check the last part of the url to see if it matches
    const arr = this.urlService.getCurrentRouteUrl().split('/');
    const last = arr[arr.length - 1];
    return item.url.includes(last);
  }

  processComponentData(data) {
    // map the navigation to the appropriate schema
    // make copy of data so it is not mutated
    const itemListElement = Array.from(data).map((m: any, i) => ({
      name: m.name,
      url: this.urlService.getHost() + m['url'],
      '@type': 'SiteNavigationElement',
      position: i + 1,
    }));
    const siteNavigationSchema = {
      '@context': 'http://schema.org',
      '@type': 'ItemList',
      itemListElement: itemListElement,
    };

    // create or modify the site navigation schema
    const script =
      (this.document.getElementById('SiteNavigationSchema') as HTMLScriptElement) ||
      this.document.head.appendChild(this.document.createElement('script'));
    script.type = 'application/ld+json';
    script.id = 'SiteNavigationSchema';
    script.text = JSON.stringify(siteNavigationSchema);
  }
}
