import { gql } from 'apollo-angular';

export const GRAPH_QUERY = {
  GET_ASSOCIATE_LOOKUP: gql`
    query getAssociateSearch($customerId: String, $firstName: String, $lastName: String, $captcha: String) {
      getAssociateSearch(customerId: $customerId, firstName: $firstName, lastName: $lastName, captcha: $captcha) {
        searchResultsCount
        results {
          firstName
          lastName
          customerId
          classification
        }
      }
    }
  `,
};
