<div class={{navigationMenu}}>
<div class={{backgroundCssClass}}>
  <div class="menu-wrap" *ngIf="dataIsLoaded">
    <div class="menu-top">

        <div class="mobile-menu">
          <u-menu-mobile-toggle></u-menu-mobile-toggle>
        </div>
        <div class="logo hideformobile">
          <a href="/ux/dotcom/" data-automation="logo-home">
            <img [ngClass]="isBaoying() ? 'desktop-logo baoying-logo' : 'desktop-logo'" src="{{isBaoying() ? 'assets/logo_usana&babycare.png' : 'assets/USANA_logo.svg'}}" alt="{{'home' | i18n:'usanadotcom'}}">
          </a>
        </div>
        <u-search-box></u-search-box>
        <div [ngClass]="isBaoying() ? 'hidefordesktop mobile-logo-container baoying-logo' : 'hidefordesktop mobile-logo-container'">
          <a href="/ux/dotcom/" data-automation="logo-mobile-home">
            <img class="mobile-logo" src="{{isBaoying() ? 'assets/logo-mobile.png' : 'assets/USANA_logo.svg'}}" alt="{{'home' | i18n:'usanadotcom'}}">
          </a>
        </div>
      <span class="menu-item hideformobile left-top-nav-baseline">
        <ng-container *ngIf="isLoggedIn() else login">
          <ng-container *ngIf="['A','D','EM'].includes(customerType); else showLogout">
            <a [href]="hubUrl" data-automation="hub-link">
              <span [innerHTML]="'the-hub' | i18n:'usanadotcom'"></span>
            </a>
          </ng-container>
          <ng-template #showLogout>
            <a href (click)="logout()" data-automation="hub-logout">
              <span [innerHTML]="'logout' | i18n:'usanadotcom'"></span>
            </a>
          </ng-template>
        </ng-container>
        <ng-template #login>
          <a href="{{hubUrl}}" (click)="goToHubOrLogin($event)" attr.data-automation="{{'hub-' + urlPathId(hubUrl)}}">
            <span [innerHTML]="'login' | i18n:'usanadotcom'"></span>
          </a>
        </ng-template>
      </span>

      <span class="menu-item hideformobile left-top-nav-baseline" *ngIf="!isBaoying()">
        <u-choose-locale-toggle></u-choose-locale-toggle>
      </span>
    </div>
    <div class="menu-bottom hideformobile">
      <u-menu-desktop></u-menu-desktop>
    </div>
  </div>
</div>
</div>
