import {Component, ElementRef} from '@angular/core';
import {BaseComponent} from '../base.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import {LocaleService} from '@shared/lib/services/locale.service';
import {Meta, Title} from '@angular/platform-browser';
import {UrlService} from '@shared/lib/services/url.service';
import {TranslationMapperService} from '@shared/lib/services/translation-mapper.service';
import {AuthService} from '@shared/lib/services/auth.service';
import {ColorService} from '@shared/lib/services/color.service';
import {DataService} from '@shared/lib/services/data.service';

@Component({
  selector: 'u-sticky-banner',
  templateUrl: './sticky-banner.component.html',
  styleUrls: ['./sticky-banner.component.scss'],
  providers: [{provide: BaseComponent, useExisting: StickyBannerComponent}]
})
export class StickyBannerComponent extends BaseComponent {
  bannerBackgroundColor = '#343a40'; // the original color, from btn-dark
  bannerTextColor = '#ffffff';
  src = 'sticky-banner';
  customerType = 'R';
  visible = true;
  map = {
    campaign: 'campaign',
    linkUrl: 'linkUrl',
    linkText: 'linkText',
    aoUpsellLinkUrl: 'aoUpsellLinkUrl',
    aoUpsellLinkText: 'aoUpsellLinkText',
    contactUsLink: 'contactUsLink',
    text: 'text',
    backgroundColor: 'backgroundColor'
  };

  // TODO: any links in the modal are part of translation content,
  //  so in order for GTM/GA to track it right, the <a> in the translation tag needs to have
  // the appropriate click event and data attributes to add the click to the dataLayer.

  constructor(
    protected http: HttpClient,
    localeService: LocaleService,
    titleService: Title,
    metaService: Meta,
    private modalService: NgbModal,
    urlService: UrlService,
    translationMapperService: TranslationMapperService,
    protected authService: AuthService,
    el: ElementRef,
    dataService: DataService) {
    super(http, localeService, titleService, metaService, urlService,
      translationMapperService, el, dataService);
  }

  // eslint-disable-next-line
  ngOnInit() {
    super.ngOnInit();
    this.customerType = this.authService.getCustomerType();
    this.isPCorAbove = ['PC', 'A', 'D'].indexOf(this.customerType) !== -1;
    if (sessionStorage.getItem('sticky') === 'closed') {
      this.visible = false;
    } else {
      this.visible = true;
    }
  }

  processComponentData(data) {
    if (ColorService.isColor(data.backgroundColor)) {
      this.bannerBackgroundColor = data.backgroundColor;

      // to get the text color, calculate whether black or white text is best for contrast
      this.bannerTextColor = ColorService.getContrastForColor(this.bannerBackgroundColor);
    }
  }

  open(content) {
    if (this.isBaoying()) {
      this.modalService.open(content, {windowClass: 'ao-modal-cn'});
    } else {
      this.modalService.open(content, {windowClass: 'ao-modal'});
    }
  }

  closeBanner() {
    sessionStorage.setItem('sticky', 'closed');
    this.visible = false;
  }
}
