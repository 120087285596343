import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UrlService} from './url.service';
import {SharedDotcomConstants} from '@shared/lib/shared-dotcom.constants';
import {CookieService} from 'ngx-cookie-service';
import {SsoAuthService} from '@usana/ux/sso-auth';
import {SsoJwtMockerService} from '@usana/ux/sso-auth/mocker';
import {LocaleService} from '@shared/lib/services/locale.service';
import {ENVIRONMENT_SSO_ENABLED} from '@shared/lib/shared.tokens';

@Injectable()
export class AuthService {

  private customer: any;

  constructor(protected http: HttpClient,
              private urlService: UrlService,
              private cookieService: CookieService,
              private ssoAuthService: SsoAuthService,
              private localeService: LocaleService,
              @Inject(ENVIRONMENT_SSO_ENABLED) private readonly environmentSsoEnabled: string) {

  }

  isSetup(): boolean {
    return !!this.customer;
  }

  isLoggedIn(): boolean {
    return this.isSetup() && this.customer.token !== SharedDotcomConstants.NO_TOKEN;
  }

  goToHubOrLogin(): void {
    const webcontent = 'webcontent';
    const subdomain = this.urlService.getSubdomain();
    if (subdomain === webcontent) {
      const webcontentUri = `${this.urlService.getDomainWithSubdomain(webcontent)}/hub/#/home`;
      this.urlService.goTo(webcontentUri);
    } else if (this.isLoggedIn() || !this.environmentSsoEnabled) {
      this.urlService.goTo(this.urlService.getHubUrl());
    } else {
      const ssoUri = `${this.urlService.getDomainWithSubdomain('sso')}/${this.localeService.getLocale()}/login`;
      this.urlService.goTo(ssoUri);
    }
  }

  getUser(logoutOnFailure: boolean): any {
    // if we already have a customer than we don't need to get a new one
    if (!this.isSetup()) {
      this.http.get(this.urlService.getCartUserUrl(), {withCredentials: true}).subscribe((data: any) => {
        this.customer = data;
        this.ssoAuthService.setUserFromIdToken(
          SsoJwtMockerService.createTokenWithJunkSignature({
            customerType: this.customer.customerType,
            roles: this.customer.authorities,
            exp: SsoJwtMockerService.dateClaimCurrentWithOffset(1000 * 60 * 30)
          })
        );
        this.setCustomerTypeCookie(data.customer.customerType);
        if (!this.isLoggedIn()) {
          // we don't have a token so we need to set the retail id cookie
          this.setRetailCookie(data.customer.id);
        }
      }, (err) => {
        if (logoutOnFailure && err.status === 403) {
          this.logout().then(() => {
            this.getUser(false);
          }, () => {
            this.getUser(false);
          });
        }
      });
    }
  }

  logout(): Promise<any[]> {
    const {FS} = (window as any);
    if (FS) {
      FS.anonymize();
    }

    const shopLogoutPromise = this.http.get(this.urlService.getDomainWithSubdomain('shop') +
      '/shop/spring/logout?redirect=false', {withCredentials: true}).toPromise();
    const portalLogoutPromise = this.http.get(this.urlService.getDomainWithSubdomain('www') +
      '/mvc/auth/logout', {headers: {'Accept': 'text/plain'}}).toPromise();
    this.customer.token = SharedDotcomConstants.NO_TOKEN;
    this.ssoAuthService.clearCurrent();
    return Promise.all([shopLogoutPromise, portalLogoutPromise]);
  }

  setRetailCookie(customerId: string): void {
    const yearDate = new Date();
    yearDate.setDate(yearDate.getDate() + 365);
    this.cookieService.set(SharedDotcomConstants.RETAIL_ID_COOKIE, customerId,
      yearDate,
      '/',
      this.urlService.getDomain());
  }

  setCustomerTypeCookie(customerType: string): void {
    const yearDate = new Date();
    yearDate.setDate(yearDate.getDate() + 365);
    this.cookieService.set(SharedDotcomConstants.CUSTOMER_TYPE_COOKIE, customerType,
      yearDate,
      '/',
      this.urlService.getDomain());
  }

  getCustomerType(): string {
    if (this.cookieService.get('Customer-Type')) {
      return this.cookieService.get('Customer-Type');
    } else {
      return 'R';
    }
  }
}
