import { NgModule } from '@angular/core';

import { UsanaRoutingModule } from './usana-routing.module';

import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { PageNotFoundModule } from '@shared/lib/modules/page-not-found/page-not-found.module';
import { SafePipesModule } from '@shared/lib/pipes/safe-pipes.module';
import { SharedModule } from '@shared/lib/shared.module';
import { SharedProviders } from '@shared/lib/shared.providers';
import { URL_SITE_DOMAIN } from '@usana/ux/common/url';
import { I18nHonorCookie, I18nInfoConfig, I18nOrganization, MarketActiveStatus } from '@usana/ux/markets';
import { RECAPTCHA_ENABLED, RECAPTCHA_SITE_KEY, UsanaReCaptchaModule } from '@usana/ux/recaptcha';
import { ID_TOKEN_NAME } from '@usana/ux/sso-auth';
import { AdminToolboxRegistrationService, SsoAdminToolboxModule } from '@usana/ux/sso-auth/admin-toolbox';
import { SsoAuthBackendLoaderModule } from '@usana/ux/sso-auth/backend-loader';
import { UsanaTranslationsEditingModule } from '@usana/ux/translations';
import { ApolloModule } from 'apollo-angular';
import { environment } from '../environments/environment';
import { UsanaAppComponent } from './components/app-root/usana-app.component';
import { CcpaComponent } from './components/ccpa/ccpa.component';
import { FloatingPresenceComponent } from './components/floating-presence/floating-presence.component';
import { FooterComponent } from './components/footer/footer.component';
import { ToolboxTranslationsComponent } from './components/toolbox-translations/toolbox-translations.component';
import { AssociateSearchModule } from './lazy-modules/associate-search/associate-search.module';
import { UsanaFontAwesomeModule } from './usana-font-awesome.module';
import { Locales, Markets } from './usana.providers';

@NgModule({
  declarations: [
    ToolboxTranslationsComponent,
    UsanaAppComponent,
    FooterComponent,
    CcpaComponent,
    FloatingPresenceComponent,
  ],
  imports: [
    BrowserModule,
    UsanaRoutingModule,
    SharedModule,
    PageNotFoundModule,
    UsanaTranslationsEditingModule,
    FormsModule,
    SsoAdminToolboxModule,
    SsoAuthBackendLoaderModule,
    SafePipesModule,
    UsanaReCaptchaModule,
    UsanaFontAwesomeModule,
    AssociateSearchModule,
    ApolloModule,
  ],
  providers: [
    new SharedProviders(
      environment,
      Locales,
      Markets,
      'enu-US',
      () =>
        new I18nInfoConfig(
          false,
          false,
          true,
          false,
          true,
          false,
          false,
          false,
          MarketActiveStatus.ACTIVE,
          I18nOrganization.USANA,
          I18nHonorCookie.ALWAYS
        )
    ).providers.concat([]),
    { provide: ID_TOKEN_NAME, useValue: 'id-token' },
    { provide: RECAPTCHA_ENABLED, useValue: environment.recaptchaEnabled },
    {
      provide: RECAPTCHA_SITE_KEY,
      useValue: environment.recaptchaEnabled ? environment.recaptchaV3SiteKey : '',
    },
    { provide: URL_SITE_DOMAIN, useValue: environment.siteDomain },
  ],
  bootstrap: [UsanaAppComponent],
})
export class UsanaModule {
  constructor(private adminToolboxRegistrationService: AdminToolboxRegistrationService) {
    this.adminToolboxRegistrationService.registerToolboxComponent({
      component: ToolboxTranslationsComponent,
      isAnyAuthority: ['USANA_TRANSLATION_ADMIN', 'USANA_TRANSLATION_EDIT'],
      isAllowedOnRoute: () => true, // always allowed in dotcom
    });
  }
}
