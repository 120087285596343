<div class="lc-popup">
  <div class="popup-header">
    {{ 'welcomeToUsana' | i18n : 'usanadotcom' }}
  </div>
  <div class="popup-body">
    <div class="message">
      {{ 'confirmMarket.begin' | i18n : 'usanadotcom' }}
    </div>
    <div class="confirm">
      <div>
        {{ 'confirmMarket.select' | i18n : 'usanadotcom' }}
      </div>
      <br />
      <span class="current-locale" (click)="toggleMarketList()" tabindex="0">
        <span class="flag-icon flag-icon-{{ flag }}"></span>{{ marketName }}
        <fa-icon [icon]="['fas', 'chevron-down']"></fa-icon>
      </span>
    </div>
    <div class="markets" [hidden]="!viewMarkets">
      <div class="country" *ngFor="let m of markets">
        <div class="country-name"><span class="flag-icon flag-icon-{{ m.flag }}"></span>{{ m.name }}</div>
        <div class="language" *ngFor="let l of m.languages" (click)="iChooseYou(l.locale)" tabindex="0">
          {{ l.language }}
        </div>
      </div>
    </div>
  </div>
  <div class="popup-footer">
    <button class="btn btn-primary" (click)="iChooseYou()" tabindex="0">{{ 'OK' | i18n : 'usanadotcom' }}</button>
  </div>
</div>
