import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class SideModalService {
  modalRef: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  open(content: any, options: any = {}): void {
    this.modalRef = this.modalService.open(content, {
      ...options,
      windowClass: 'u-side-modal',
    });
  }

  close(): void {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }
}
