<div id="associate-search-modal">
  <div id="associate-search-modal-header">
    <div id="associate-search-modal-buttons">
      <div id="associate-search-buttons-left">
        <universal-button
          *ngIf="searchResults?.length"
          id="associate-search-back-button"
          data-automation="associateSearchBackButton"
          [data]="{ size: 'small', type: 'tertiary', buttonType: 'button', disabled: false }"
          (click)="onBackClicked()"
        >
          <span class="fas fa-arrow-left"></span> &nbsp;

          {{ 'associate-search.back' | i18n : 'usanadotcom' }}
        </universal-button>
      </div>

      <div>
        <universal-button
          id="associate-search-close-button"
          data-automation="associateSearchCloseButton"
          [data]="{ type: 'close', buttonType: 'button', disabled: false }"
          (click)="onCloseClicked()"
        >
        </universal-button>
      </div>
    </div>

    <h3 id="associate-search-title">{{ 'associate-search.title' | i18n : 'usanadotcom' }}</h3>
  </div>

  <div id="associate-search-modal-content">
    <div *ngIf="!searchResults?.length; else searchResultsContainer" id="associate-search-modal-search-form">
      <div *ngIf="searchResult?.data && previousSearchTerm" id="associate-search-no-results">
        {{ 'associate-search.no-results' | i18n : 'usanadotcom' }} "{{ previousSearchTerm }}"
      </div>

      <form id="associate-search-form" [formGroup]="associateSearchForm" (ngSubmit)="onAssociateSearch()">
        <universal-form-input-text
          id="associate-search-id"
          automationId="associateSearchId"
          formControlName="id"
          [label]="'associate-search.usana-id-number' | i18n : 'usanadotcom'"
          [data]="associateSearchIdData"
        ></universal-form-input-text>

        <div id="associate-search-or">
          <div class="associate-search-or-line"></div>
          <span class="associate-search-or-text">{{ 'associate-search.or' | i18n : 'usanadotcom' | uppercase }}</span>
          <div class="associate-search-or-line"></div>
        </div>

        <universal-form-input-text
          id="associate-first-name"
          automationId="associateFirstName"
          formControlName="firstName"
          [label]="'associate-search.first-name' | i18n : 'usanadotcom'"
          [data]="associateFirstNameData"
        ></universal-form-input-text>

        <universal-form-input-text
          id="associate-last-name"
          automationId="associateLastName"
          formControlName="lastName"
          [label]="'associate-search.last-name' | i18n : 'usanadotcom'"
          [data]="associateLastNameData"
        ></universal-form-input-text>

        <universal-button
          id="associate-search-button"
          data-automation="associateSearchButton"
          [data]="{ size: 'full', type: 'primary', buttonType: 'submit', disabled: associateSearchForm.invalid }"
          >{{ 'associate-search.search-button' | i18n : 'usanadotcom' }}</universal-button
        >
      </form>
    </div>

    <ng-template #searchResultsContainer>
      <div id="associate-search-results">
        <div *ngFor="let result of searchResults" class="associate-search-result">
          <div class="associate-search-result-image">
            <img src="assets/enu-IN/images/associate-search-profile.svg" alt="user-image" />
          </div>

          <div class="associate-search-result-details">
            <div>USANA ID: {{ result.customerId }}</div>
            <div>{{ result.firstName }} {{ result.lastName }}</div>
            <div>District: {{ result.district }}</div>

            <ng-container *ngIf="result.classification !== DELISTED_CLASSIFICATION; else inactiveUser">
              <div class="associate-search-result-active">Active</div>
            </ng-container>
            <ng-template #inactiveUser>
              <div class="associate-search-result-inactive">Inactive</div>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
