<div *ngIf="show()" class="my-usana {{ stateClass }}" attr.data-automation="my-{{ stateClass }}">
  <a
    *ngIf="!stateClass.includes('max'); else my_usana_max"
    class="minimized-container"
    (click)="toggle()"
    data-automation="maximize"
  >
    <div class="fp-image-container">
      <div class="fp-image">
        <div class="tiny-thumb-container">
          <div
            data-automation="profile-image-min"
            *ngIf="floatingPresence.params.pic; else noProfileImageSmall"
            [ngStyle]="{ 'background-image': 'url(' + floatingPresence.params['pic'].value + ')' }"
            class="tiny-thumb"
          ></div>
        </div>
        <ng-template #noProfileImageSmall>
          <img
            data-automation="profile-default-image-min"
            src="./assets/no-profile-image.png"
            class="img-fluid"
            alt=""
          />
        </ng-template>
      </div>
    </div>
    <div class="link-text" [innerHTML]="floatingPresence.params['name'].value"></div>

    <fa-icon [icon]="['fas', 'chevron-down']"></fa-icon>
  </a>
  <ng-template #my_usana_max>
    <div class="maximized-container">
      <div class="mobile-close" (click)="toggle()">
        <fa-icon [icon]="['fas', 'times']"></fa-icon>
      </div>

      <div class="grid-container container">
        <div class="grid-item grid-item1">
          <div class="person-image-container" style="background-image: url('./assets/my-usana-bg.png')">
            <div
              *ngIf="floatingPresence.params['pic'].value; else noProfileImage"
              data-automation="profile-image"
              class="person-image"
              [ngStyle]="{ 'background-image': 'url(' + floatingPresence.params['pic'].value + ')' }"
            ></div>
            <ng-template #noProfileImage>
              <div
                class="person-image"
                data-automation="profile-default-image"
                [ngStyle]="{ 'background-image': 'url(./assets/no-profile-image.png)' }"
              ></div>
            </ng-template>
          </div>
          <div class="contact-me-container">
            <h3 [innerHTML]="floatingPresence.params['name'].value"></h3>
            <h4
              *ngIf="floatingPresence.params['email'].visible || floatingPresence.params['phone'].visible"
              [innerHTML]="'my-usana.contact-me' | i18n : 'usanadotcom'"
            >
              Contact Me
            </h4>
            <div *ngIf="floatingPresence.params['phone'] && floatingPresence.params['phone'].visible">
              <a
                target="_blank"
                rel="noopener"
                href="tel:{{ floatingPresence.params['phone'].value }}"
                data-automation="my-phone"
              >
                <fa-icon [icon]="MOBILE_ICON" class="contact-icon"></fa-icon>
                {{ floatingPresence.params['phone'].value }}
              </a>
            </div>
            <div *ngIf="floatingPresence.params['email'] && floatingPresence.params['email'].visible">
              <a
                target="_blank"
                rel="noopener"
                href="mailto:{{ floatingPresence.params['email'].value }}"
                data-automation="my-email"
              >
                <fa-icon [icon]="EMAIL_ICON" class="contact-icon"></fa-icon>
                {{ floatingPresence.params['email'].value }}
              </a>
            </div>
            <div class="person-social">
              <a
                *ngIf="floatingPresence.params['facebook'].value && floatingPresence.params['facebook'].visible"
                data-automation="my-facebook"
                href="{{ floatingPresence.params['facebook'].value }}"
                rel="noopener"
                target="_blank"
                [attr.aria-label]="facebook"
                class="social facebook"
              >
                <fa-icon [icon]="['fab', 'facebook']" class="contact-icon fa-lg"></fa-icon>
              </a>
              <a
                *ngIf="floatingPresence.params['instagram'].value && floatingPresence.params['instagram'].visible"
                data-automation="my-instagram"
                href="{{ floatingPresence.params['instagram'].value }}"
                rel="noopener"
                target="_blank"
                [attr.aria-label]="instagram"
                class="social instagram"
              >
                <fa-icon [icon]="['fab', 'instagram']" class="contact-icon fa-lg"></fa-icon>
              </a>
              <a
                *ngIf="floatingPresence.params['pinterest'].value && floatingPresence.params['pinterest'].visible"
                data-automation="my-pinterest"
                href="{{ floatingPresence.params['pinterest'].value }}"
                rel="noopener"
                target="_blank"
                [attr.aria-label]="pinterest"
                class="social pinterest"
              >
                <fa-icon [icon]="['fab', 'pinterest']" class="contact-icon fa-lg"></fa-icon>
              </a>
              <a
                *ngIf="floatingPresence.params['linkedin'].value && floatingPresence.params['linkedin'].visible"
                data-automation="my-linkedin"
                href="{{ floatingPresence.params['linkedin'].value }}"
                rel="noopener"
                target="_blank"
                [attr.aria-label]="linkedin"
                class="social linkedin"
              >
                <fa-icon [icon]="['fab', 'linkedin']" class="contact-icon fa-lg"></fa-icon>
              </a>
            </div>
          </div>
        </div>
        <div class="grid-item grid-item2">
          <div class="flex-container">
            <h3 [innerHTML]="tags.heading | i18n : 'usanadotcom'"></h3>
            <ng-container *ngIf="floatingPresence.params['mystory'].value; else defaultMyStory">
              <div [innerHTML]="floatingPresence.params['mystory'].value" data-automation="my-story"></div>
            </ng-container>
            <ng-template #defaultMyStory>
              <div [innerHTML]="tags.myStory | i18n : 'usanadotcom'" data-automation="my-story-default"></div>
            </ng-template>
            <div class="enroll-links">
              <ng-container *ngIf="floatingPresence.params['join'].value === 'true'; else upsellButton">
                <a
                  data-automation="my-enroll-link"
                  class="btn btn-primary"
                  target="_blank"
                  rel="noopener"
                  [href]="enrollLink()"
                  >{{ tags.enrollmentText | i18n : 'usanadotcom' }}</a
                >
                <a
                  [href]="'my-usana.pc-upsell-link' | i18n : 'usanadotcom'"
                  target="_blank"
                  rel="noopener"
                  [innerHTML]="'my-usana.pc-upsell-text' | i18n : 'usanadotcom'"
                  data-automation="pc-upsell-link"
                ></a>
              </ng-container>
              <ng-template #upsellButton>
                <a
                  [href]="'my-usana.pc-upsell-link' | i18n : 'usanadotcom'"
                  class="btn btn-primary"
                  target="_blank"
                  rel="noopener"
                  data-automation="pc-upsell-link"
                >
                  <span [innerHTML]="'my-usana.pc-upsell-text' | i18n : 'usanadotcom'"></span>
                </a>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="grid-item grid-item3">
          <ng-container *ngIf="displayFavorites; else featuredHeading"
            ><h3 [innerHTML]="'my-usana.favorites-heading' | i18n : 'usanadotcom'"></h3
          ></ng-container>
          <ng-template #featuredHeading
            ><h3 [innerHTML]="'my-usana.top-selling' | i18n : 'usanadotcom'"></h3
          ></ng-template>
          <div class="my-favorites-container">
            <!-- this is modified from cart ux -->
            <section class="featured-landing" *ngIf="!!featuredProducts && featuredProducts.length > 0">
              <div class="horizontal-scroll-product-row">
                <div
                  *ngFor="let product of removeDuplicateProducts(featuredProducts)"
                  class="featured-product--itemsWrapper"
                >
                  <div
                    class="featured-product--items"
                    (click)="goToProduct(product.usanaId)"
                    [attr.data-automation]="'my-favorites-' + product.usanaId"
                  >
                    <img class="img-responsive" [src]="product.productImages[0].imagePath" alt="" />
                    <div
                      [innerHTML]="product.productDescription.name"
                      class="featured-product-name"
                      automation-tag="featuredProductName"
                    ></div>
                    <div class="featured-details">
                      <div *ngIf="showPricing" class="featured-current-price" automation-tag="featuredCurrentPrice">
                        {{ showPriceByProduct(product) }}
                      </div>
                      <!-- <div *ngIf="showPricing && showRetailStrikethroughByProduct(product)" class="featured-retail-price featured-content" automation-tag="featuredRetailPrice">
                                {{'cart.product.retail.price' | i18n: 'usanadotcom'}} {{product.retailPrice.priceDisplay}}
                            </div>
                            <div *ngIf="showPcComparisonByProduct(product)" class="featured-content" automation-tag="featuredPcPrice">
                                {{'cart.product.pc.price' | i18n: 'usanadotcom'}} {{product.pcPrice.priceDisplay}}
                            </div>
                            <div *ngIf="shouldDisplayVolume" class="featured-content">
                                {{'cart.product.recommended.volume' | i18n: 'usanadotcom'}} {{product.price.volume}}
                            </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div class="add-to-cart-links" *ngIf="showPricing">
            <ng-container *ngIf="displayFavorites; else featuredAddAll"
              ><span
                ><a
                  (click)="addAllToCart()"
                  href
                  [innerHTML]="'my-usana.favorites-add-all-text' | i18n : 'usanadotcom'"
                ></a></span
            ></ng-container>
            <ng-template #featuredAddAll
              ><span
                ><a
                  (click)="addAllToCart()"
                  data-automation="my-add-to-cart"
                  href
                  [innerHTML]="'my-usana.top-selling-add-all-text' | i18n : 'usanadotcom'"
                ></a></span
            ></ng-template>
            <a
              class="btn btn-primary"
              target="_blank"
              rel="noopener"
              href="/ux/cart"
              data-automation="my-shop"
              [innerHTML]="'my-usana.favorites-shop-more-text' | i18n : 'usanadotcom'"
            ></a>
          </div>
        </div>
      </div>
    </div>
    <a (click)="toggle()" class="minimize-link" data-automation="minimize">
      <fa-icon [icon]="['fas', 'chevron-up']"></fa-icon>
    </a>
  </ng-template>
</div>
