import {Component, ViewChild, ElementRef, Output, EventEmitter} from '@angular/core';
import {BaseComponent} from '@shared/lib/components/base.component';
import {HttpClient} from '@angular/common/http';
import {Meta, Title} from '@angular/platform-browser';
import {LocaleService} from '@shared/lib/services/locale.service';
import {MarketService} from '@shared/lib/services/market.service';
import {UrlService} from '@shared/lib/services/url.service';
import {TranslationMapperService} from '@shared/lib/services/translation-mapper.service';
import {UsanaMarketService} from '@usana/ux/markets';
import {DataService} from '@shared/lib/services/data.service';
import {AuthService} from '@shared/lib/services/auth.service';

@Component({
  selector: 'u-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss'],
})
export class MenuMobileComponent extends BaseComponent {
  src = 'menu';
  market_name = '';
  current_market = {};
  selected_market = {};
  current_market_locale = {};
  rootToMarkets = false;
  marketsToLanguages = false;
  backToMarkets = false;
  backToRoot = false;
  back = false;
  markets = [];
  hubUrl: string;
  customerType: string;
  @ViewChild('languagePickerMobile') languagePicker: ElementRef;

  @Output() readonly forceClose = new EventEmitter<void>();

  map = [
    {
      url: '0.url',
      name: '0.name',
      target: '0.target',
    },
    {
      url: '1.url',
      name: '1.name',
      target: '1.target',
    },
    {
      url: '2.url',
      name: '2.name',
      target: '2.target',
    },
    {
      url: '3.url',
      name: '3.name',
      target: '3.target',
    },
    {
      url: '4.url',
      name: '4.name',
      target: '4.target',
    },
    {
      url: '5.url',
      name: '5.name',
      target: '5.target',
    },
  ];


  constructor(protected http: HttpClient,
              localeService: LocaleService,
              titleService: Title,
              marketService: MarketService,
              metaService: Meta,
              urlService: UrlService,
              translationMapperService: TranslationMapperService,
              private usanaMarketService: UsanaMarketService,
              el: ElementRef,
              dataService: DataService) {
    super(http, localeService, titleService, metaService, urlService,
      translationMapperService, el, dataService);
    this.markets = marketService.markets;
    this.hubUrl = urlService.getHubUrl();
  }

  handleLink(event: any, url: string, target: string): void {
    this.urlService.handleAnchorEvent(event, url, target);
    this.forceClose.emit();
  }

  goToHubOrLogin(event: any): void {
    event.preventDefault();
    this.authService.goToHubOrLogin();
  }

  marketLookup(locale) {
    this.markets.filter((market) => {
      market.languages.filter((loc) => {
        if (locale === loc.locale) {
          this.current_market = market;
          this.current_market_locale = loc;
          this.market_name = loc.name + ' - ' + loc.language;
          return loc as any;
        }
      });
    });
  }

  // This does not set the market or language, it only animates the market menu.
  selectMarket() {
    this.rootToMarkets = true;
    this.backToMarkets = false;
    this.backToRoot = false;
  }

  // This does not set the market or language, it only sets the content of the languages menu.
  selectLanguages(name) {
    this.marketsToLanguages = true;
    this.backToMarkets = false;
    this.backToRoot = false;
    this.markets.filter((market) => {
      if (name === market.name) {
        // move keyboard focus to first button on language picker
        this.languagePicker.nativeElement.querySelector('li > button').focus();
        this.selected_market = market;
        return market;
      }
    });
  }

  backFromLanguages() {
    // this.rootToMarkets = false;
    this.marketsToLanguages = false;
    this.backToMarkets = true;
    this.backToRoot = false;
  }

  backFromMarkets() {
    this.rootToMarkets = false;
    this.marketsToLanguages = false;
    this.backToMarkets = false;
    this.backToRoot = true;
  }

  setLocale(newLocale) {
    this.usanaMarketService.setMarketId(newLocale);
  }

  protected localeChangeBeforeLoadContent(newLocale: string): void {
    this.marketLookup(newLocale);
  }

  protected localeChangeAfterLoadContent(newLocale: string): void {
    this.backFromMarkets();
  }

  isLoggedIn() {
    this.customerType = this.authService.getCustomerType();
    return this.authService.isLoggedIn();
  }

  logout() {
    this.authService.logout().then(() => {
      window.location.href = this.hubUrl;
    });
  }
}
