<div *ngIf="dataIsLoaded" class="text-center">
  <div class="container">
    <h2 [innerHTML]="data.heading"></h2>
    <h3 [innerHTML]="data.subheading" *ngIf="data.subheading"></h3>
    <p class="text-center" [innerHTML]="data.text"></p>
  </div>

  <div class="teamusana-card-flex" *ngIf="data.athletes">
    <div class="card-flex-item" *ngFor="let item of data.athletes">
      <div class="img-wrapper"><img [src]="item.image" class="w-100" alt=""/>
        <div class="card-caption">
          <h3 class="mb-1">{{item.name}}</h3>
          <p class="font-weight-light">{{item.country}}, {{item.sport}}</p>
        </div>
      </div>
    </div>
  </div>

  <h3>Global Partners</h3>
  <div class="teamusana-logo-flex">
    <div *ngFor="let item of data.logos" class="logo-flex-item">
      <img [src]="item.src" class="w-100" alt="">
    </div>
  </div>

  <div *ngIf="data.linkUrl">
    <a href="{{data.linkUrl}}" class="btn btn-primary" target="_blank"> {{data.linkText}} </a>
  </div>

</div>
