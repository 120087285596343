export const SharedDotcomConstants = {
  // translation
  TRANSLATION_NAMESPACE: 'usanadotcom',
  TRANSLATION_TTL: 7200000,

  // routing
  DEFAULT_PAGE: 'home',

  // rendering
  PRERENDER_USER_AGENT: /prerender/i,

  // auth
  NO_TOKEN: 'noTokenSet',
  RETAIL_ID_COOKIE: 'Retail-Id',
  CUSTOMER_TYPE_COOKIE: 'Customer-Type',
  SHOPPER_SOURCE_PARAM: 'shopperSource',
  SHOPPER_SOURCE_VALUE: 'PHX-newDotCom',
  CONFIRMED_LOCALE_COOKIE: 'i18n.cookie.confirmed',
  EAGER_RENDER_COOKIE: 'eager.eagle',

  // floating presence
  FP_WEBSITE_ID_COOKIE: 'MOST_RECENT_PWP_WEBSITE_ID_VISITED',
  FP_WEBSITE_SUBDOMAIN_COOKIE: 'com.obs.webHosting.WEBHOST_SITE_COOKIE',

  // GDPR compliance
  // only show cookie notification for the Markets Belgium,	France,	Germany,	Italy,	Netherlands,	Romania,	Spain,	United Kingdom
  GDPR_COOKIE_COUNTRY_DISPLAY_LIST: ['BE', 'FR', 'DE', 'NL', 'RO', 'ES', 'UK', 'IT'],
  GDPR_COOKIE: 'usana.gdpr.acceptance.cookie',

  // environments host in China
  HOST_CN: 'https://today.baoying.com',

  PLACEHOLDER_IMG: 'assets/placeholder.jpeg',
};
