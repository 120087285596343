import {Injectable, Inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UrlService} from './url.service';
import {ENVIRONMENT_CART_SERVICES_URL} from '@shared/lib/shared.tokens';
import {FloatingPresence} from '@shared/lib/model/floating-presence';

@Injectable()
export class FloatingPresenceService {

  constructor(private http: HttpClient, private urlService: UrlService,
              @Inject(ENVIRONMENT_CART_SERVICES_URL) private readonly environmentCartServicesUrl: string) {

  }

  loadFloatingPresence(): Promise<FloatingPresence> {
    return new Promise<FloatingPresence>((resolve, reject) => {
      if (this.seemsLikeSubdomain()) {
        this.http.get(`/mvc/floatingPresence/${this.urlService.getSubdomain()}/get`)
          .toPromise().then((fp: FloatingPresence) => {
            resolve(fp);
          });
      } else {
        resolve(new FloatingPresence());
      }
    });
  }

  getEnrollLink(): string {
    return `/mvc/floatingPresence/${this.urlService.getSubdomain()}/enroll`;
  }

  seemsLikeSubdomain(): boolean {
    return this.urlService.getSubdomain() !== 'www';
  }

  loadFeaturedProducts(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.seemsLikeSubdomain()) {
        this.http.get(`${this.environmentCartServicesUrl}/featuredProductList/landing/`, {withCredentials: true})
          .toPromise().then((featured: any) => {
            resolve(featured);
          });
      } else {
        resolve({});
      }
    });
  }

  loadProduct(usanaId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.seemsLikeSubdomain()) {
        this.http.get(`${this.environmentCartServicesUrl}/productGroup/byProduct/${usanaId}`, {withCredentials: true})
          .toPromise().then((product: any) => {
            resolve(product);
          });
      } else {
        resolve({});
      }
    });

  }
}
