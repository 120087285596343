import {Component, ElementRef, HostListener} from '@angular/core';
import {BaseComponent} from '../base.component';
import {HttpClient} from '@angular/common/http';
import {Meta, Title} from '@angular/platform-browser';
import {LocaleService} from '@shared/lib/services/locale.service';
import {UrlService} from '@shared/lib/services/url.service';
import {TranslationMapperService} from '@shared/lib/services/translation-mapper.service';
import {DataService} from '@shared/lib/services/data.service';
import {AuthService} from '@shared/lib/services/auth.service';

declare const window: any;
let lastScrollTop = 0;
const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
const delta = 5;
const navbarHeight = 150;
let elapsed, timer, count = 10; // eslint-disable-line


@Component({
  selector: 'u-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent extends BaseComponent {
  src = 'menu';
  backgroundCssClass = 'navbar-container';
  navigationMenu = 'nav-down';
  hubUrl: string;
  customerType: string;

  // urlService is used in html
  constructor(protected http: HttpClient,
              localeService: LocaleService,
              titleService: Title,
              metaService: Meta,
              urlService: UrlService,
              translationMapperService: TranslationMapperService,
              el: ElementRef,
              dataService: DataService) {
    super(http, localeService, titleService, metaService, urlService,
      translationMapperService, el, dataService);
    this.hubUrl = urlService.getHubUrl();
  }

  goToHubOrLogin(event: any): void {
    event.preventDefault();
    this.authService.goToHubOrLogin();
  }

  timeoutHandler = (st) => {
    if (!elapsed) {
      clearTimeout(timer);
    } else {
      elapsed = false;
    }
    count++;
    timer = setTimeout(() => {
      this.hasScrolled(st);
      elapsed = true;
    }, 25);

  }

  hasScrolled = (st) => {

    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - st) <= delta) {
      return;
    }
    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st >= navbarHeight) {
      // Scroll Down
      // hide on scroll
      this.navigationMenu = 'nav-up';
      // We want to hide both the red and black bars on scroll down every time
    } else {

      this.navigationMenu = 'nav-down';
      // If we are more than 50px from the top then we want to show only
    }
    lastScrollTop = st;
  }

  @HostListener('window:scroll', ['$event'])
  scrollFunc = () => {
    const st = this.window.scrollY;
    this.timeoutHandler(st);
    if (this.window.scrollY > 0) {
      this.backgroundCssClass = 'navbar-container-after-scroll';
    } else {
      this.backgroundCssClass = 'navbar-container-onscrollup';
    }
  }

  isLoggedIn() {
    this.customerType = this.authService.getCustomerType();
    return this.authService.isLoggedIn();
  }

  logout() {
    this.authService.logout().then(() => {
      this.window.location.href = this.hubUrl;
    });
  }
}
