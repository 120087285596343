import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '@shared/lib/components/base.component';
import { ModalComponent } from '@shared/lib/components/modal/modal.component';
import { DataService } from '@shared/lib/services/data.service';
import { LocaleService } from '@shared/lib/services/locale.service';
import { PageStructureService } from '@shared/lib/services/page-structure.service';
import { SideModalService } from '@shared/lib/services/side-modal.service';
import { TranslationMapperService } from '@shared/lib/services/translation-mapper.service';
import { UrlService } from '@shared/lib/services/url.service';
import { AssociateSearchModalComponent } from '../../lazy-modules/associate-search/components/associate-search-modal/associate-search-modal.component';
import { faEnvelope, faPhone, faAngleDown } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'u-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None, // eslint-disable-line
})
export class FooterComponent extends BaseComponent {
  readonly ENVELOPE_ICON = faEnvelope;
  readonly PHONE_ICON = faPhone;
  readonly ANGLE_DOWN_ICON = faAngleDown;

  src = 'footer';
  map = {
    emailText: 'emailText',
    followText: 'followText',
    socialMedia: [
      {
        social: 'socialMedia.0.social',
        url: 'socialMedia.0.url',
      },
      {
        social: 'socialMedia.1.social',
        url: 'socialMedia.1.url',
      },
      {
        social: 'socialMedia.2.social',
        url: 'socialMedia.2.url',
      },
      {
        social: 'socialMedia.3.social',
        url: 'socialMedia.3.url',
      },
      {
        social: 'socialMedia.4.social',
        url: 'socialMedia.4.url',
      },
      {
        social: 'socialMedia.5.social',
        url: 'socialMedia.5.url',
      },
    ],
    followTextLocale: 'followTextLocale',
    socialMediaLocale: [
      {
        social: 'socialMediaLocale.0.social',
        url: 'socialMediaLocale.0.url',
      },
      {
        social: 'socialMediaLocale.1.social',
        url: 'socialMediaLocale.1.url',
      },
      {
        social: 'socialMediaLocale.2.social',
        url: 'socialMediaLocale.2.url',
      },
      {
        social: 'socialMediaLocale.3.social',
        url: 'socialMediaLocale.3.url',
      },
      {
        social: 'socialMediaLocale.4.social',
        url: 'socialMediaLocale.4.url',
      },
      {
        social: 'socialMediaLocale.5.social',
        url: 'socialMediaLocale.5.url',
      },
    ],
    disclaimer1: 'disclaimer1',
    disclaimer2: 'disclaimer2',
    disclaimer3: 'disclaimer3',
    disclaimer4: 'disclaimer4',
    disclaimer5: 'disclaimer5',
    cookiesettings: 'cookiesettings',
    adaPolicy: 'adaPolicy',
    copyright: 'copyright',
    footerExtra: 'footerExtra',
    legal: [
      {
        url: 'legal.0.url',
        name: 'legal.0.name',
        target: 'legal.0.target',
      },
      {
        url: 'legal.1.url',
        name: 'legal.1.name',
        target: 'legal.1.target',
      },
      {
        url: 'legal.2.url',
        name: 'legal.2.name',
        target: 'legal.2.target',
      },
      {
        url: 'legal.3.url',
        name: 'legal.3.name',
        target: 'legal.3.target',
      },
      {
        url: 'legal.4.url',
        name: 'legal.4.name',
        target: 'legal.4.target',
      },
    ],
    items: [
      {
        name: 'items.0.name',
        children: [
          {
            url: 'items.0.children.0.url',
            name: 'items.0.children.0.name',
            target: 'items.0.children.0.target',
          },
          {
            url: 'items.0.children.1.url',
            name: 'items.0.children.1.name',
            target: 'items.0.children.1.target',
          },
          {
            url: 'items.0.children.2.url',
            name: 'items.0.children.2.name',
            target: 'items.0.children.2.target',
          },
          {
            url: 'items.0.children.3.url',
            name: 'items.0.children.3.name',
            target: 'items.0.children.3.target',
          },
          {
            url: 'items.0.children.4.url',
            name: 'items.0.children.4.name',
            target: 'items.0.children.4.target',
          },
          {
            url: 'items.0.children.5.url',
            name: 'items.0.children.5.name',
            target: 'items.0.children.5.target',
          },
          {
            url: 'items.0.children.6.url',
            name: 'items.0.children.6.name',
            target: 'items.0.children.6.target',
          },
          {
            url: 'items.0.children.7.url',
            name: 'items.0.children.7.name',
            target: 'items.0.children.7.target',
          },
          {
            url: 'items.0.children.8.url',
            name: 'items.0.children.8.name',
            target: 'items.0.children.8.target',
          },
          {
            url: 'items.0.children.9.url',
            name: 'items.0.children.9.name',
            target: 'items.0.children.9.target',
          },
          {
            url: 'items.0.children.10.url',
            name: 'items.0.children.10.name',
            target: 'items.0.children.10.target',
          },
          {
            url: 'items.0.children.11.url',
            name: 'items.0.children.11.name',
            target: 'items.0.children.11.target',
          },
          {
            url: 'items.0.children.12.url',
            name: 'items.0.children.12.name',
            target: 'items.0.children.12.target',
          },
        ],
      },
      {
        name: 'items.1.name',
        children: [
          {
            url: 'items.1.children.0.url',
            name: 'items.1.children.0.name',
            target: 'items.1.children.0.target',
          },
          {
            url: 'items.1.children.1.url',
            name: 'items.1.children.1.name',
            target: 'items.1.children.1.target',
          },
          {
            url: 'items.1.children.2.url',
            name: 'items.1.children.2.name',
            target: 'items.1.children.2.target',
          },
          {
            url: 'items.1.children.3.url',
            name: 'items.1.children.3.name',
            target: 'items.1.children.3.target',
          },
          {
            url: 'items.1.children.4.url',
            name: 'items.1.children.4.name',
            target: 'items.1.children.4.target',
          },
          {
            url: 'items.1.children.5.url',
            name: 'items.1.children.5.name',
            target: 'items.1.children.5.target',
          },
          {
            url: 'items.1.children.6.url',
            name: 'items.1.children.6.name',
            target: 'items.1.children.6.target',
          },
          {
            url: 'items.1.children.7.url',
            name: 'items.1.children.7.name',
            target: 'items.1.children.7.target',
          },
          {
            url: 'items.1.children.8.url',
            name: 'items.1.children.8.name',
            target: 'items.1.children.8.target',
          },
          {
            url: 'items.1.children.9.url',
            name: 'items.1.children.9.name',
            target: 'items.1.children.9.target',
          },
          {
            url: 'items.1.children.10.url',
            name: 'items.1.children.10.name',
            target: 'items.1.children.10.target',
            action: 'items.1.children.10.action',
          },
          {
            url: 'items.1.children.11.url',
            name: 'items.1.children.11.name',
            target: 'items.1.children.11.target',
            action: 'items.1.children.11.action',
          },
          {
            url: 'items.1.children.12.url',
            name: 'items.1.children.12.name',
            target: 'items.1.children.12.target',
            action: 'items.1.children.12.action',
          },
        ],
      },
      {
        name: 'items.2.name',
        children: [
          {
            url: 'items.2.children.0.url',
            name: 'items.2.children.0.name',
            target: 'items.2.children.0.target',
          },
          {
            url: 'items.2.children.1.url',
            name: 'items.2.children.1.name',
            target: 'items.2.children.1.target',
          },
        ],
      },
    ],
  };

  // used when the footer link needs to trigger a non-navigation action, ex: open a modal.
  // add the action here. set the 'action' translation tag value to the key of the action.
  linkActionsMap = {
    openAssociateSearch: this.openAssociateSearchModal.bind(this),
    openIndiaGrievancesModal: this.openIndiaGrievancesModal.bind(this),
  };

  public isCollapsed = [true, true, true];

  constructor(
    protected http: HttpClient,
    localeService: LocaleService,
    titleService: Title,
    metaService: Meta,
    urlService: UrlService,
    translationMapperService: TranslationMapperService,
    pageStructureService: PageStructureService,
    el: ElementRef,
    dataService: DataService,
    private modalService: NgbModal,
    private sideModalService: SideModalService
  ) {
    super(http, localeService, titleService, metaService, urlService, translationMapperService, el, dataService);
    pageStructureService.setFooterStructure({
      src: this.src,
      map: this.map,
    });
  }

  onFooterLinkClicked(child: any): void {
    if (child && this.linkActionsMap[child.action]) {
      this.linkActionsMap[child.action]();
    }
  }

  openIndiaGrievancesModal() {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.src = 'grievances-modal';
  }

  openAssociateSearchModal() {
    this.sideModalService.open(AssociateSearchModalComponent);
  }
}
