<div class="u-modal">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>

    <div class="modal-title">{{ src + '.heading' | i18n : 'usanadotcom' }}</div>
  </div>

  <div class="modal-body text-start" *ngIf="dataIsLoaded" [innerHTML]="src + '.text' | i18n : 'usanadotcom'"></div>
</div>
