import { Provider } from '@angular/core';
import { DotcomMarket } from '@shared/lib/model/dotcom-market';
import { Locale } from '@shared/lib/model/locale';
import {
  DEFAULT_LOCALE,
  ENVIRONMENT_CART_SERVICES_URL,
  ENVIRONMENT_HOST,
  ENVIRONMENT_PRODUCTION,
  ENVIRONMENT_SITE_DOMAIN,
  ENVIRONMENT_SSO_ENABLED,
  SUPPORTED_LOCALES,
  SUPPORTED_MARKETS,
} from '@shared/lib/shared.tokens';
import { GRAPH_URL_MARKET, I18N_INFO_CONFIG } from '@usana/ux/markets';
import { ID_TOKEN_NAME, LOGIN_REALM, LOGIN_SERVICE_URL } from '@usana/ux/sso-auth';
import { GRAPH_URL_TRANSLATION, REST_URL_TRANSLATION, TRANS_TOOL_ADMIN_URI } from '@usana/ux/translations';

export class SharedProviders {
  readonly providers: Provider[];

  constructor(
    environment: any,
    locales: { [localeKey: string]: Locale },
    markets: DotcomMarket[],
    defaultLocale: string,
    i18nConfigFactory: any
  ) {
    this.providers = [
      { provide: ID_TOKEN_NAME, useValue: 'id_token' },
      { provide: LOGIN_SERVICE_URL, useValue: `https://api.${environment.siteDomain}/auth/ws` },
      { provide: LOGIN_REALM, useValue: environment.loginRealm },
      { provide: GRAPH_URL_TRANSLATION, useValue: `${environment.i18nService}/graphql` },
      { provide: REST_URL_TRANSLATION, useValue: environment.i18nService },
      { provide: GRAPH_URL_MARKET, useValue: `${environment.i18nService}/graphql` },
      { provide: SUPPORTED_LOCALES, useValue: locales },
      { provide: DEFAULT_LOCALE, useValue: defaultLocale },
      { provide: SUPPORTED_MARKETS, useValue: markets },
      { provide: ENVIRONMENT_HOST, useValue: environment.host },
      { provide: ENVIRONMENT_CART_SERVICES_URL, useValue: environment.cartService },
      { provide: ENVIRONMENT_SITE_DOMAIN, useValue: environment.siteDomain },
      { provide: ENVIRONMENT_PRODUCTION, useValue: environment.production },
      { provide: ENVIRONMENT_SSO_ENABLED, useValue: environment.ssoEnabled },
      { provide: TRANS_TOOL_ADMIN_URI, useValue: `https://csr.${environment.siteDomain}/ui/teas/tool/translation` },
      {
        provide: I18N_INFO_CONFIG,
        useFactory: i18nConfigFactory,
        multi: true,
      },
    ];
  }
}
