<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div
    class="modal-body text-start p-5"
    *ngIf="dataIsLoaded"
    [innerHTML]="data.text"
    [attr.data-id]="data.linkUrl"
    [attr.data-position]="src + '_modal'"
    attr.data-name="{{ src + '.campaign' | i18n : 'usanadotcom' }}"
    [attr.data-creative]="data.linkText"
    inViewport
    (inViewportAction)="captureView($event)"
  ></div>
</ng-template>

<ng-container *ngIf="dataIsLoaded && visible">
  <button
    *ngIf="!hasInnerText(data.linkUrl) && hasInnerText(data.linkText)"
    [style.background-color]="bannerBackgroundColor"
    [style.color]="bannerTextColor"
    class="btn btn-block"
    (click)="open(content)"
  >
    <small>
      <strong>{{ data.linkText }}</strong>
    </small>

    <span aria-hidden="true" (click)="closeBanner()" class="close">
      <fa-icon [icon]="['fal', 'times']"></fa-icon>
    </span>
  </button>

  <span *ngIf="hasInnerText(data.linkUrl) && !isPCorAbove && hasInnerText(data.linkText)" class="btn-block">
    <a
      [style.background-color]="bannerBackgroundColor"
      [style.color]="bannerTextColor"
      class="btn"
      target="_blank"
      [attr.data-automation]="urlPathId(data.linkUrl)"
      [href]="data.linkUrl"
      [attr.data-id]="data.linkUrl"
      [attr.data-position]="src + '_direct_link'"
      attr.data-name="{{ src + '.campaign' | i18n : 'usanadotcom' }}"
      [attr.data-creative]="data.linkText"
      inViewport
      (inViewportAction)="captureView($event)"
      (click)="captureClick($event)"
    >
      <small>
        <strong>{{ data.linkText }}</strong>
      </small>
    </a>

    <span aria-hidden="true" (click)="closeBanner()" class="close">
      <fa-icon [icon]="['fal', 'times']"></fa-icon>
    </span>
  </span>

  <span *ngIf="hasInnerText(data.aoUpsellLinkUrl) && isPCorAbove" class="btn-block">
    <a
      [style.background-color]="bannerBackgroundColor"
      [style.color]="bannerTextColor"
      class="btn"
      target="_blank"
      [attr.data-automation]="urlPathId(data.aoUpsellLinkUrl)"
      [href]="data.aoUpsellLinkUrl"
      [attr.data-id]="data.linkUrl"
      [attr.data-position]="src + '_direct_link'"
      attr.data-name="{{ src + '.campaign' | i18n : 'usanadotcom' }}"
      [attr.data-creative]="data.aoUpsellText"
      inViewport
      (inViewportAction)="captureView($event)"
      (click)="captureClick($event)"
    >
      <small>
        <strong>{{ data.aoUpsellLinkText }}</strong>
      </small>
    </a>

    <span aria-hidden="true" (click)="closeBanner()" class="close">
      <fa-icon [icon]="['fal', 'times']"></fa-icon>
    </span>
  </span>
</ng-container>
