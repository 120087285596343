import { Component, OnChanges } from '@angular/core';
import { PageComponent } from '@shared/lib/components/page.component';

@Component({
  selector: 'u-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent extends PageComponent {
  src = 'page-not-found';

  protected defaultComponentData() {
    this.data = Object.assign({}, {
      page_title: ''
    });
    this.dataIsLoaded = true;
  }
}
