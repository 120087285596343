import {Injectable} from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs';
import {PageStructure} from '@shared/lib/model/page-structure';
import {ComponentTranslationMapping} from '@shared/lib/model/component-translation-mapping';

@Injectable()
export class PageStructureService {

  private currentPageStructure: PageStructure;
  private pageStructureSubject: ReplaySubject<PageStructure>;

  constructor() {
    this.currentPageStructure = {
      header: null,
      footer: null,
      pageTranslations: null,
      components: []
    };
    this.pageStructureSubject = new ReplaySubject<PageStructure>(1);
  }

  setHeaderStructure(header: ComponentTranslationMapping) {
    this.currentPageStructure.header = header;
    this.pageStructureSubject.next(this.currentPageStructure);
  }

  setFooterStructure(footer: ComponentTranslationMapping) {
    this.currentPageStructure.footer = footer;
    this.pageStructureSubject.next(this.currentPageStructure);
  }

  setCurrentPageStructure(pageTranslations: ComponentTranslationMapping,
                          components: ComponentTranslationMapping[]): void {
    this.currentPageStructure.pageTranslations = pageTranslations;
    this.currentPageStructure.components = components;

    this.pageStructureSubject.next(this.currentPageStructure);
  }

  getPageStructureSubject(): Subject<PageStructure> {
    return this.pageStructureSubject;
  }
}



