import {PageNotFoundComponent} from '@shared/lib/modules/page-not-found/components/page-not-found.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    PageNotFoundComponent,
  ],
  imports: [
    CommonModule,
  ],
  providers: [
  ],
  exports: [
    PageNotFoundComponent
  ]
})
export class PageNotFoundModule {
}
