import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocaleService } from '@shared/lib/services/locale.service';
import { Meta, Title } from '@angular/platform-browser';
import { UrlService } from '@shared/lib/services/url.service';
import { TranslationMapperService } from '@shared/lib/services/translation-mapper.service';
import { DataService } from '@shared/lib/services/data.service';
import { AuthService } from '../services/auth.service';
import { WINDOW_TOKEN } from '@usana/ux/common';

@Component({
  selector: 'u-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseComponent implements OnInit, OnDestroy {
  @Input() src: any = '';

  // default to Retail customer type if nothing is provided.
  @Input() customertype: any = 'R';
  @Input() position: any;
  el: ElementRef;
  hasRetailShopping = false;
  isPCorAbove = false;
  isRetail = false;

  data;
  protected map;
  localeService;
  titleService;
  metaService;
  urlService;
  dataService;
  translationMapperService;
  dataIsLoaded = false;
  supportedLocale = true;
  syncLoadcontent = false;
  componentDataSubscription;
  localeSubscription;
  lazyLoad;
  protected authService: AuthService | undefined;
  window;

  constructor(
    protected http: HttpClient,
    localeService: LocaleService,
    titleService: Title,
    metaService: Meta,
    urlService: UrlService,
    translationMapperService: TranslationMapperService,
    el: ElementRef,
    dataService: DataService
  ) {
    this.localeService = localeService;
    this.titleService = titleService;
    this.metaService = metaService;
    this.urlService = urlService;
    this.translationMapperService = translationMapperService;
    this.el = el;
    this.dataService = dataService;
    this.authService = inject(AuthService);
    this.window = inject(WINDOW_TOKEN);
  }

  ngOnInit(): void {
    this.lazyLoad = Array(6).fill(this.dataService.isEagerRendering());
    // individual components can now have knowledge of customer if we tell it
    this.hasRetailShopping = this.localeService.getLocaleObject().hasRetailShopping;
    this.isPCorAbove = ['PC', 'A', 'D'].indexOf(this.customertype) !== -1;
    this.isRetail = this.customertype === 'R';

    if (typeof this.src === 'string') {
      this.defaultComponentData();
      const loc: string = this.localeService.getLocale();
      if (this.src.trim().length > 0) {
        this.el.nativeElement.setAttribute('data-automation', this.src);
        // This is only relevant for admin users
        if (this.authService.getCustomerType() === 'EM') {
          this.el.nativeElement.setAttribute('data-src', this.src);
          this.el.nativeElement.setAttribute('data-map', JSON.stringify(this.map));
        }
      }

      if (!!loc) {
        // we already have one so we don't have to wait for the subscription
        this.localeChangeBeforeLoadContent(loc);
        if (!this.syncLoadcontent) {
          this.loadContent();
          this.localeChangeAfterLoadContent(loc);
        }
      }
      this.localeSubscription = this.localeService.getLocaleObservable().subscribe((locale) => {
        this.localeChangeBeforeLoadContent(locale);
        this.loadContent();
        this.localeChangeAfterLoadContent(locale);
      });
    } else {
      this.data = this.src as any;

      this.dataIsLoaded = true;
    }
  }

  ngOnDestroy(): void {
    if (!!this.componentDataSubscription) {
      this.componentDataSubscription.unsubscribe();
    }
    if (!!this.localeSubscription) {
      this.localeSubscription.unsubscribe();
    }
  }

  hasInnerText(key) {
    if (key !== undefined || key !== null) {
      const elem = this.createElementFromHTML(key) as any;
      const len = elem.innerText.trim().length;
      return len > 0 ? true : false;
    } else {
      return false;
    }
  }

  createElementFromHTML(htmlString) {
    const div = document.createElement('div');
    if (htmlString !== undefined) {
      div.innerHTML = (htmlString as any) + ' '.trim();
    }
    // Change this to div.childNodes to support multiple top-level nodes
    return div;
  }

  protected localeChangeBeforeLoadContent(newLocale: string): void {
    // implement this if you want to use it
  }

  protected localeChangeAfterLoadContent(newLocale: string): void {
    // implement this if you want to use it
  }

  protected processComponentData(data): void {
    // implement this if you want to process the data loaded from the json for this component
  }

  protected defaultComponentData(): void {
    // implement this if you want to have a 'empty' version of the component that renders faster
    this.dataIsLoaded = false; // todo double check this can be here.
  }

  protected loadContent(): void {
    this.translationMapperService.translateFromMap(this.src, this.map).then((tData) => {
      this.data = tData;
      this.processComponentData(this.data);
      this.dataIsLoaded = true;
    });
  }

  public marketIn(list: string): boolean {
    return list.split(',').indexOf(this.localeService.getLocale()) >= 0;
  }

  public isBaoying(): boolean {
    return this.localeService.isBaoyingMarket();
  }

  // this captures the info needed for when we queue up some promos for a dataLayer.push()
  // the push happens in the page.component ("makeImpression()")
  captureView({ target, visible }: { target: Element; visible: boolean }): void {
    if (visible) {
      const promo = {
        id: target.attributes['data-id'].value,
        name: target.attributes['data-name'] ? target.attributes['data-name'].value : undefined,
        creative: target.attributes['data-creative'].value,
        position: `dotcom_${target.attributes['data-position'].value}`,
      };
      const promoViewArr = this.window.impressionBucket.filter((r) => r.event === 'EEpromotionView');
      // add to the last impression
      const promos = promoViewArr[promoViewArr.length - 1].ecommerce.promoView.promotions;
      if (promos.length === 0 || promos.findIndex((x) => x.position === promo.position) === -1) {
        promos.push(promo);
      }
    }
  }

  captureClick({ currentTarget, visible }: { currentTarget: Element; visible: boolean }): void {
    const promo = {
      id: currentTarget.attributes['data-id'].value,
      name: currentTarget.attributes['data-name'].value,
      creative: currentTarget.attributes['data-creative'].value,
      position: `dotcom_${currentTarget.attributes['data-position'].value}`,
    };
    const obj = {
      event: 'EEpromotionClick',
      ecommerce: {
        promoClick: {
          promotions: [promo],
        },
      },
    };
    this.window.dataLayer = this.window.dataLayer || [];
    this.window.dataLayer.push(obj);
  }

  urlPathId(url) {
    if (url !== null && url !== undefined) {
      const urlWithoutTrailingSlash = url.replace(/\/$/, '');
      const urlArr = urlWithoutTrailingSlash.split('/');
      return this.el.nativeElement.tagName + '~' + urlArr[urlArr.length - 1].split('?')[0];
    } else {
      return this.el.nativeElement.tagName + '~';
    }
  }
}
