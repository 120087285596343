<div>
  <ul
    class="list-group market-list"
    [class.markets-to-languages]="marketsToLanguages"
    [class.back-to-markets]="backToMarkets"
  >
    <li class="list-group-item">
      <h2 [innerHTML]="current_market_locale.select_text"></h2>
    </li>
    <li class="list-group-item" *ngFor="let item of markets">
      <button class="u-media market-button" (click)="selectLanguages(item.name)" [attr.data-automation]="item.flag">
        <i class="flag-icon flag-icon-{{ item.flag }}"></i>

        <div class="media-body">{{ item.name }}</div>

        <fa-icon *ngIf="item.languages" [icon]="['fal', 'angle-right']"></fa-icon>
      </button>
    </li>
  </ul>

  <ul class="list-group language-list" #languagePicker>
    <li class="list-group-item">
      <button class="u-media" (click)="backFromLanguages()">
        <fa-icon [icon]="['fal', 'angle-left']"></fa-icon>

        <div class="media-body">
          <strong [innerHTML]="current_market_locale.back_text"></strong>
        </div>
      </button>
    </li>

    <li class="list-group-item" *ngFor="let item of selected_market.languages">
      <button
        class="u-media"
        (click)="setLocale(item.locale)"
        [attr.data-locale]="item.locale"
        [attr.data-automation]="item.locale"
      >
        <div class="media-body" lang="{{ item.locale.split('-')[0] }}">{{ item.language }}</div>
      </button>
    </li>
  </ul>
</div>
