import { Component, DoCheck, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { LocaleService } from '@shared/lib/services/locale.service';
import { MarketService } from '@shared/lib/services/market.service';
import { UsanaMarketService } from '@usana/ux/markets';
import { DotcomMarket } from '@shared/lib/model/dotcom-market';
import { MarketLanguageSelection } from '@shared/lib/model/market-language-selection';

@Component({
  selector: 'u-choose-locale',
  templateUrl: './choose-locale.component.html',
  styleUrls: ['./choose-locale.component.scss'],
})
export class ChooseLocaleComponent implements DoCheck {
  locale;
  market_name = '';
  current_market = {};
  selected_market: DotcomMarket = new DotcomMarket();
  current_market_locale = <any>{}; // eslint-disable-line
  rootToMarkets = false;
  marketsToLanguages = false;
  backToMarkets = false;
  backToRoot = false;
  back = false;
  markets: DotcomMarket[] = [];
  @ViewChild('languagePicker', { static: true }) languagePicker: ElementRef;

  constructor(
    locale: LocaleService,
    private marketService: MarketService,
    private usanaMarketService: UsanaMarketService,
    private renderer: Renderer2
  ) {
    this.locale = locale;
    this.markets = marketService.markets;
  }

  marketLookup(locale) {
    const mls: MarketLanguageSelection = this.marketService.marketFromLocale(locale);
    if (mls) {
      this.current_market = mls.market;
      this.current_market_locale = mls.getLanguage();
      this.market_name = mls.getMarketName();
      return this.current_market_locale as any;
    }
  }

  // This does not set the market or language, it only animates the market menu.
  selectMarket() {
    this.rootToMarkets = true;
    this.backToMarkets = false;
    this.backToRoot = false;
  }

  // This does not set the market or language, it only sets the content of the languages menu.
  selectLanguages(name) {
    this.marketsToLanguages = true;
    this.backToRoot = false;
    this.markets.filter((market) => {
      if (name === market.name) {
        // move keyboard focus to first button on language picker
        this.languagePicker.nativeElement.querySelector('li > button').focus();
        this.selected_market = market;
        return market;
      }
    });
  }

  backFromLanguages() {
    // this.rootToMarkets = false;
    this.marketsToLanguages = false;
    this.backToMarkets = true;
    this.backToRoot = false;
  }

  backFromMarkets() {
    this.rootToMarkets = false;
    this.marketsToLanguages = false;
    this.backToMarkets = false;
    this.backToRoot = true;
  }

  setLocale(newLocale) {
    this.backFromLanguages();
    this.usanaMarketService.setMarketId(newLocale);
  }

  ngDoCheck() {
    this.marketLookup(this.locale.getLocale());
  }
}
