import { Locale } from '@shared/lib/model/locale';
import { DotcomMarket } from '@shared/lib/model/dotcom-market';

export const Locales: { [localeKey: string]: Locale } = {
  'enu-US': {
    key: 'enu-US',
    warehouse: 'SLC',
    hasPHX: true,
    hasOlapic: true,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: true,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: true,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'zht-US': {
    key: 'zht-US',
    warehouse: 'SLC',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: true,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: true,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'zhs-US': {
    key: 'zhs-US',
    warehouse: 'SLC',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: true,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: true,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'spa-US': {
    key: 'spa-US',
    warehouse: 'SLC',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: true,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: true,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'enu-AU': {
    key: 'enu-AU',
    warehouse: 'SYD',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: true,
    hasWeightManagement: true,
    hasBlog: false,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'zhs-AU': {
    key: 'zhs-AU',
    warehouse: 'SYD',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: true,
    hasWeightManagement: true,
    hasBlog: false,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'enu-NZ': {
    key: 'enu-NZ',
    warehouse: 'WDB',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: true,
    hasWeightManagement: true,
    hasBlog: false,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'zhs-NZ': {
    key: 'zhs-NZ',
    warehouse: 'ALB',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: true,
    hasWeightManagement: true,
    hasBlog: false,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'enu-CA': {
    key: 'enu-CA',
    warehouse: 'ALB',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: true,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'fre-CA': {
    key: 'fre-CA',
    warehouse: 'ALB',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: true,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'zht-CA': {
    key: 'zht-CA',
    warehouse: 'ALB',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: true,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'zhs-CA': {
    key: 'zhs-CA',
    warehouse: 'ALB',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: true,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'enu-GB': {
    key: 'enu-GB',
    warehouse: 'NLD',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: false,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'zht-TW': {
    key: 'zht-TW',
    warehouse: 'TW',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: false,
    hasWeightManagement: true,
    hasBlog: false,
    hasAtsSearch: true,
    hasRetailShopping: false,
  },
  'tha-TH': {
    key: 'tha-TH',
    warehouse: 'TH',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: false,
    hasOlivol: false,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: false,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: false,
    hasRetailShopping: true,
  },
  'zhs-SG': {
    key: 'zhs-SG',
    warehouse: 'SG',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: false,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: false,
  },
  'enu-SG': {
    key: 'enu-SG',
    warehouse: 'SG',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: false,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: false,
  },
  'enu-RO': {
    key: 'enu-RO',
    warehouse: 'NLD',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: false,
    hasWeightManagement: false,
    hasBlog: true,
    hasAtsSearch: false,
    hasRetailShopping: true,
  },
  'ron-RO': {
    key: 'ron-RO',
    warehouse: 'NLD',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: false,
    hasWeightManagement: false,
    hasBlog: true,
    hasAtsSearch: false,
    hasRetailShopping: true,
  },
  'enu-PH': {
    key: 'enu-PH',
    warehouse: 'MAN',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: true,
    hasWeightManagement: true,
    hasBlog: false,
    hasAtsSearch: true,
    hasRetailShopping: false,
  },
  'enu-NL': {
    key: 'enu-NL',
    warehouse: 'NL',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: false,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'fre-NL': {
    key: 'fre-NL',
    warehouse: 'NL',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: false,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'enu-MY': {
    key: 'enu-MY',
    warehouse: 'MY',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: false,
    hasEnergy: false,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'msa-MY': {
    key: 'msa-MY',
    warehouse: 'MY',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: false,
    hasEnergy: false,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'zhs-MY': {
    key: 'zhs-MY',
    warehouse: 'MY',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: false,
    hasEnergy: false,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'spa-MX': {
    key: 'spa-MX',
    warehouse: 'MXC',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: false,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: false,
    hasDrLee: false,
    hasEnergy: false,
    hasWeightManagement: false,
    hasBlog: true,
    hasAtsSearch: false,
    hasRetailShopping: true,
  },
  'kor-KR': {
    key: 'kor-KR',
    warehouse: 'KR',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: false,
    hasEnergy: false,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: false,
  },
  'enu-KR': {
    key: 'enu-KR',
    warehouse: 'KR',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: false,
    hasEnergy: false,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: false,
  },
  'jpn-JP': {
    key: 'jpn-JP',
    warehouse: 'JP',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: false,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: false,
    hasRetailShopping: false,
  },
  'enu-JP': {
    key: 'enu-JP',
    warehouse: 'JP',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: false,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: false,
  },
  'ita-IT': {
    key: 'ita-IT',
    warehouse: 'NLD',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: false,
    hasWeightManagement: false,
    hasBlog: true,
    hasAtsSearch: false,
    hasRetailShopping: true,
  },
  'enu-IT': {
    key: 'enu-IT',
    warehouse: 'NLD',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: false,
    hasWeightManagement: false,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'ind-ID': {
    key: 'ind-ID',
    warehouse: 'ID',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: false,
    hasWeightManagement: false,
    hasBlog: true,
    hasAtsSearch: false,
    hasRetailShopping: false,
  },
  'enu-ID': {
    key: 'enu-ID',
    warehouse: 'JKT',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: false,
    hasWeightManagement: false,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: false,
  },
  'zht-HK': {
    key: 'zht-HK',
    warehouse: 'HK',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: false,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: false,
  },
  'enu-HK': {
    key: 'enu-HK',
    warehouse: 'HK',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: false,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: false,
  },
  'fre-FR': {
    key: 'fre-FR',
    warehouse: 'NLD',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: false,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'enu-FR': {
    key: 'enu-FR',
    warehouse: 'NLD',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: false,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'enu-ES': {
    key: 'enu-ES',
    warehouse: 'NLD',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: false,
    hasWeightManagement: false,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'spa-ES': {
    key: 'spa-ES',
    warehouse: 'NLD',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: false,
    hasWeightManagement: false,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'deu-DE': {
    key: 'deu-DE',
    warehouse: 'NLD',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: false,
    hasWeightManagement: false,
    hasBlog: true,
    hasAtsSearch: false,
    hasRetailShopping: true,
  },
  'enu-DE': {
    key: 'enu-DE',
    warehouse: 'NLD',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: false,
    hasWeightManagement: false,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'spa-CO': {
    key: 'spa-CO',
    warehouse: 'BOG',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: false,
    hasWeightManagement: false,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'nld-BE': {
    key: 'nld-BE',
    warehouse: 'NLD',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: false,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'fre-BE': {
    key: 'fre-BE',
    warehouse: 'NLD',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: false,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'enu-BE': {
    key: 'enu-BE',
    warehouse: 'NLD',
    hasPHX: false,
    hasOlapic: false,
    hasIncelligence: true,
    hasHybridTechnology: true,
    hasOlivol: true,
    hasScienceDisclaimer: false,
    hasChampions: true,
    hasDrLee: true,
    hasEnergy: false,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
  'enu-IN': {
    key: 'enu-IN',
    warehouse: 'SLC',
    hasPHX: true,
    hasOlapic: false,
    hasIncelligence: false,
    hasHybridTechnology: false,
    hasOlivol: false,
    hasScienceDisclaimer: true,
    hasChampions: true,
    hasDrLee: false,
    hasEnergy: false,
    hasWeightManagement: true,
    hasBlog: true,
    hasAtsSearch: true,
    hasRetailShopping: true,
  },
};

export const Markets: DotcomMarket[] = [
  {
    name: 'United States',
    flag: 'us',
    languages: [
      {
        name: 'USA',
        locale: 'enu-US',
        language: 'English',
        select_text: 'Select Market &amp; Language',
        back_text: 'Back',
      },
      {
        name: 'USA',
        locale: 'spa-US',
        language: 'Español',
        select_text: 'Seleccione mercado e idioma',
        back_text: 'Volver',
      },
      {
        name: 'USA',
        locale: 'zht-US',
        language: '繁體中文',
        select_text: '选择市场和语言',
        back_text: '返回',
      },
      {
        name: 'USA',
        locale: 'zhs-US',
        language: '簡體中文',
        select_text: '选择市场和语言',
        back_text: '返回',
      },
    ],
  },
  {
    name: 'Canada',
    flag: 'ca',
    languages: [
      {
        name: 'CAN',
        locale: 'enu-CA',
        language: 'English',
        select_text: 'Select Market &amp; Language',
        back_text: 'Back',
      },
      {
        name: 'CAN',
        locale: 'fre-CA',
        language: 'Français',
        select_text: 'Sélectionnez le marché et la langue',
        back_text: 'Revenir',
      },
      {
        name: 'CAN',
        locale: 'zht-CA',
        language: '繁體中文',
        select_text: '选择市场和语言',
        back_text: '返回',
      },
      {
        name: 'CAN',
        locale: 'zhs-CA',
        language: '簡體中文',
        select_text: '选择市场和语言',
        back_text: '返回',
      },
    ],
  },
  {
    name: 'Mexico',
    flag: 'mx',
    languages: [
      {
        name: 'MEX',
        locale: 'spa-MX',
        language: 'Español',
        select_text: 'Seleccione mercado e idioma',
        back_text: 'Volver',
      },
    ],
  },
  {
    name: 'Colombia',
    flag: 'co',
    languages: [
      {
        name: 'COL',
        locale: 'spa-CO',
        language: 'Español',
        select_text: 'Seleccione mercado e idioma',
        back_text: 'Volver',
      },
    ],
  },
  {
    name: 'Netherlands',
    flag: 'nl',
    languages: [
      {
        name: 'NLD',
        locale: 'enu-NL',
        language: 'English',
        select_text: 'Select Market &amp; Language',
        back_text: 'Back',
      },
    ],
  },
  {
    name: 'United Kingdom',
    flag: 'gb',
    languages: [
      {
        name: 'GBR',
        locale: 'enu-GB',
        language: 'English',
        select_text: 'Select Market &amp; Language',
        back_text: 'Back',
      },
    ],
  },
  {
    name: 'France',
    flag: 'fr',
    languages: [
      {
        name: 'FRA',
        locale: 'fre-FR',
        language: 'Français',
        select_text: 'Sélectionnez le marché et la langue',
        back_text: 'Revenir',
      },
      {
        name: 'FRA',
        locale: 'enu-FR',
        language: 'English',
        select_text: 'Select Market &amp; Language',
        back_text: 'Back',
      },
    ],
  },
  {
    name: 'Belgium',
    flag: 'be',
    languages: [
      {
        name: 'BEL',
        locale: 'fre-BE',
        language: 'Français',
        select_text: 'Sélectionnez le marché et la langue',
        back_text: 'Revenir',
      },
      {
        name: 'BEL',
        locale: 'enu-BE',
        language: 'English',
        select_text: 'Select Market &amp; Language',
        back_text: 'Back',
      },
    ],
  },
  {
    name: 'Germany',
    flag: 'de',
    languages: [
      {
        name: 'DEU',
        locale: 'enu-DE',
        language: 'English',
        select_text: 'Select Market &amp; Language',
        back_text: 'Back',
      },
      {
        name: 'DEU',
        locale: 'deu-DE',
        language: 'Deutsch',
        select_text: 'Wählen Sie Markt & Sprachee',
        back_text: 'Zurück',
      },
    ],
  },
  {
    name: 'Spain',
    flag: 'es',
    languages: [
      {
        name: 'ESP',
        locale: 'enu-ES',
        language: 'English',
        select_text: 'Select Market &amp; Language',
        back_text: 'Back',
      },
      {
        name: 'ESP',
        locale: 'spa-ES',
        language: 'Español',
        select_text: 'Seleccione mercado e idioma',
        back_text: 'Volver',
      },
    ],
  },
  {
    name: 'Italy',
    flag: 'it',
    languages: [
      {
        name: 'ITA',
        locale: 'enu-IT',
        language: 'English',
        select_text: 'Select Market &amp; Language',
        back_text: 'Back',
      },
      {
        name: 'ITA',
        locale: 'ita-IT',
        language: 'Italiano',
        select_text: 'Seleziona mercato e lingua',
        back_text: 'Indietro',
      },
    ],
  },
  {
    name: 'Romania',
    flag: 'ro',
    languages: [
      {
        name: 'ROU',
        locale: 'enu-RO',
        language: 'English',
        select_text: 'Select Market &amp; Language',
        back_text: 'Back',
      },
      {
        name: 'ROU',
        locale: 'ron-RO',
        language: 'Română',
        select_text: 'Selectați Market și Limbra',
        back_text: 'Înapoi',
      },
    ],
  },
  {
    name: 'Hong Kong',
    flag: 'hk',
    languages: [
      {
        name: 'HKG',
        locale: 'enu-HK',
        language: 'English',
        select_text: 'Select Market &amp; Language',
        back_text: 'Back',
      },
      {
        name: 'HKG',
        locale: 'zht-HK',
        language: '繁體中文',
        select_text: '选择市场和语言',
        back_text: '返回',
      },
    ],
  },
  {
    name: 'Japan',
    flag: 'jp',
    languages: [
      {
        name: 'JPN',
        locale: 'jpn-JP',
        language: '日本語',
        select_text: '市場と言語を選択',
        back_text: '返る',
      },
    ],
  },
  {
    name: 'Korea',
    flag: 'kr',
    languages: [
      {
        name: 'KOR',
        locale: 'kor-KR',
        language: '한국어',
        select_text: '시장 및 언어 선택',
        back_text: '돌아가다',
      },
    ],
  },
  {
    name: 'Taiwan',
    flag: 'tw',
    languages: [
      {
        name: 'TWN',
        locale: 'zht-TW',
        language: '繁體中文',
        select_text: '选择市场和语言',
        back_text: '返回',
      },
    ],
  },
  {
    name: 'Australia',
    flag: 'au',
    languages: [
      {
        name: 'AUS',
        locale: 'enu-AU',
        language: 'English',
        select_text: 'Select Market &amp; Language',
        back_text: 'Back',
      },
      {
        name: 'AUS',
        locale: 'zhs-AU',
        language: '簡體中文',
        select_text: '选择市场和语言',
        back_text: '返回',
      },
    ],
  },
  {
    name: 'New Zealand',
    flag: 'nz',
    languages: [
      {
        name: 'NZL',
        locale: 'enu-NZ',
        language: 'English',
        select_text: 'Select Market &amp; Language',
        back_text: 'Back',
      },
      {
        name: 'NZL',
        locale: 'zhs-NZ',
        language: '簡體中文',
        select_text: '选择市场和语言',
        back_text: '返回',
      },
    ],
  },
  {
    name: 'India',
    flag: 'in',
    languages: [
      {
        name: 'IND',
        locale: 'enu-IN',
        language: 'English',
        select_text: 'Select Market &amp; Language',
        back_text: 'Back',
      },
    ],
  },
  {
    name: 'Indonesia',
    flag: 'id',
    languages: [
      {
        name: 'IDN',
        locale: 'enu-ID',
        language: 'English',
        select_text: 'Select Market &amp; Language',
        back_text: 'Back',
      },
      {
        name: 'IDN',
        locale: 'ind-ID',
        language: 'Indonesian',
        select_text: 'Pilih Pasar dan Bahasa',
        back_text: 'Kembali',
      },
    ],
  },
  {
    name: 'Malaysia',
    flag: 'my',
    languages: [
      {
        name: 'MYS',
        locale: 'enu-MY',
        language: 'English',
        select_text: 'Select Market &amp; Language',
        back_text: 'Back',
      },
      {
        name: 'MYS',
        locale: 'msa-MY',
        language: 'Malay',
        select_text: 'Pilih Pasaran amp; Bahasa',
        back_text: 'Kembali',
      },
      {
        name: 'MYS',
        locale: 'zhs-MY',
        language: '簡體中文',
        select_text: '选择市场和语言',
        back_text: '返回',
      },
    ],
  },
  {
    name: 'Singapore',
    flag: 'sg',
    languages: [
      {
        name: 'SGP',
        locale: 'enu-SG',
        language: 'English',
        select_text: 'Select Market &amp; Language',
        back_text: 'Back',
      },
      {
        name: 'SGP',
        locale: 'zhs-SG',
        language: '简体中文',
        select_text: '选择市场和语言',
        back_text: '返回',
      },
    ],
  },
  {
    name: 'Thailand',
    flag: 'th',
    languages: [
      {
        name: 'THA',
        locale: 'tha-TH',
        language: 'ภาษาไทย',
        select_text: 'เลือกตลาดและภาษา',
        back_text: 'กลับ',
      },
    ],
  },
  {
    name: 'Philippines',
    flag: 'ph',
    languages: [
      {
        name: 'PHL',
        locale: 'enu-PH',
        language: 'English',
        select_text: 'Select Market &amp; Language',
        back_text: 'Back',
      },
    ],
  },
];
