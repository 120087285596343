import { Component, ElementRef } from '@angular/core';
import { BaseComponent } from '@shared/lib/components/base.component';
import { MarketService } from '@shared/lib/services/market.service';
import { HttpClient } from '@angular/common/http';
import { LocaleService } from '@shared/lib/services/locale.service';
import { Meta, Title } from '@angular/platform-browser';
import { UrlService } from '@shared/lib/services/url.service';
import { TranslationMapperService } from '@shared/lib/services/translation-mapper.service';
import { DataService } from '@shared/lib/services/data.service';

@Component({
  selector: 'u-choose-locale-toggle',
  templateUrl: './choose-locale-toggle.component.html',
  styleUrls: ['./choose-locale-toggle.component.scss'],
})
export class ChooseLocaleToggleComponent extends BaseComponent {
  menuIsOpen = false;
  current_flag;
  select_text: string;

  private readonly theClass = 'blocked-by-desktop-menu';

  constructor(
    protected http: HttpClient,
    localeService: LocaleService,
    titleService: Title,
    metaService: Meta,
    urlService: UrlService,
    translationMapperService: TranslationMapperService,
    protected marketService: MarketService,
    el: ElementRef,
    dataService: DataService
  ) {
    super(http, localeService, titleService, metaService, urlService, translationMapperService, el, dataService);
  }

  openDesktopMenu() {
    this.menuIsOpen = true;
    document.body.classList.add(this.theClass);
  }

  closeDesktopMenu() {
    this.menuIsOpen = false;
    document.body.classList.remove(this.theClass);
  }

  updateFlag(loc: string): void {
    const current_market_locale = this.marketService.marketFromLocale(loc);
    this.select_text = current_market_locale.market.languages.filter((n) => n.locale === loc)[0].select_text;
    const current_locale = loc;
    if (current_market_locale.market.flag !== undefined) {
      this.current_flag = current_market_locale.market.flag;
    } else {
      if (current_locale.split('-')[1] !== undefined) {
        this.current_flag = current_locale.split('-')[1].toLowerCase();
      }
    }
  }

  protected localeChangeAfterLoadContent(newLocale: string) {
    this.updateFlag(newLocale);
    this.closeDesktopMenu();
  }
}
