import {DotcomMarket} from './dotcom-market';
import {MarketLanguage} from './market-language';

export class MarketLanguageSelection {
  market: DotcomMarket;
  languageIdx: number;

  constructor(market: DotcomMarket, languageIdx: number) {
    this.market = market;
    this.languageIdx = languageIdx;
  }

  getLanguage(): MarketLanguage {
    return this.market.languages[this.languageIdx];
  }

  getMarketName(): string {
    const lang = this.getLanguage();
    return lang.name + ' - ' + lang.language;
  }

  getLocale(): string {
    return this.getLanguage().locale;
  }
}
