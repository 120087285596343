/* eslint-disable */
import { Injectable } from '@angular/core';
export const COLORS = {
  Blue100: [185, 217, 235],
  Blue200: [105, 180, 231],
  Blue300: [65, 143, 222],
  Blue400: [0, 114, 206],
  Blue500: [0, 80, 181],
  Blue600: [0, 51, 133],
  Green100: [212, 235, 142],
  Green200: [151, 215, 0],
  Green300: [132, 189, 0],
  Green400: [80, 158, 47],
  Green500: [74, 119, 41],
  Green600: [28, 66, 32],
  Teal100: [140, 226, 208],
  Teal200: [73, 197, 177],
  Teal300: [0, 179, 152],
  Teal400: [0, 155, 119],
  Teal500: [0, 106, 82],
  Teal600: [0, 76, 69],
  Spruce100: [209, 244, 215],
  Spruce200: [148, 183, 187],
  Spruce300: [127, 169, 174],
  Spruce400: [79, 134, 142],
  Spruce500: [17, 94, 103],
  Spruce600: [0, 72, 81],
  Silver100: [209, 221, 230],
  Silver200: [166, 187, 200],
  Silver300: [122, 153, 172],
  Silver400: [79, 117, 139],
  Silver500: [52, 101, 127],
  Silver600: [0, 62, 81],
  Grey100: [244, 244, 244],
  Grey200: [219, 226, 233],
  Grey300: [166, 187, 200],
  Grey400: [122, 153, 172],
  Grey500: [104, 129, 151],
  Grey600: [87, 114, 139],
  Grey700: [66, 85, 99],
  Grey800: [51, 63, 72],
  Grey900: [19, 30, 41],
  Red100: [250, 187, 203],
  Red200: [255, 141, 161],
  Red300: [255, 88, 93],
  Red400: [238, 39, 55],
  Red500: [210, 38, 48],
  Red600: [164, 52, 58],
  Fuschia100: [234, 190, 219],
  Fuschia200: [242, 119, 198],
  Fuschia300: [233, 60, 172],
  Fuschia400: [225, 0, 152],
  Fuschia500: [198, 0, 126],
  Fuschia600: [162, 0, 103],
  Purple100: [234, 184, 228],
  Purple200: [221, 126, 211],
  Purple300: [201, 100, 207],
  Purple400: [176, 0, 142],
  Purple500: [137, 3, 118],
  Purple600: [109, 32, 119],
  Rose100: [228, 190, 195],
  Rose200: [196, 32, 114],
  Rose300: [180, 107, 122],
  Rose400: [152, 72, 86],
  Rose500: [145, 47, 70],
  Rose600: [101, 29, 50],
  Yellow100: [248, 229, 154],
  Yellow200: [255, 198, 88],
  Yellow300: [255, 184, 28],
  Yellow400: [242, 150, 31],
  Yellow500: [212, 93, 0],
  Yellow600: [185, 71, 0],
  Black: [0, 0, 0],
  White: [255, 255, 255],
};


@Injectable()
export class ColorService {

  /**
   *
   * @param String key       name from the COLORS list
   * @return String         returns a CSS RGB value 'rgb(x,x,x)'
   */
  static brandColorAsCSS(key) {
    return ColorService.rgbArrayToRgbString(COLORS[key]);
  }

  /**
   *
   * @param String key      name from the COLORS list
   * @return Array         an Array representing the RGB values
   */
  static brandColorAsArray(key) {
    return COLORS[key];
  }

  /**
   *
   * @param String color      a string that should be a color
   * @return boolean         is true if the string is a CSS hex or rgb color string
   */
  static isColor(color) {
    return ColorService.isHex(color) || ColorService.isRgb(color);
  }

  /**
   *
   * @param String color      a string that should be a hex color value
   * @return boolean         return true if it is a hex color, false if it is not
   */
  static isHex(color) {
    // checks length too since the methods in this service need all 6 characters (not 3)
    return color.indexOf('#') === 0 && color.length === 7;
  }

  /**
   *
   * @param String color    a string that should be a CSS rgb value 'rgb(x,x,x)'
   * @return boolean       return true if looks like a proper CSS rgb value, false if it is not
   */
  static isRgb(color) {
    return color.indexOf('rgb(') === 0; // isn't super robust but should be sufficient
  }

  /**
   *
   * @param String str takes an rgb(x,x,x) string
   * @return Array  representing RGB values [x,x,x]
   */
  static rgbStringToRgbArray(str) {
    return str.replace('rgb(', '').replace(')', '').replace(' ', '').split(',');
  }

  /**
   *
   * @param Array arr    takes an rgb array [x,x,x]
   * @return String     represents a CSS rgb string rgb(x,x,x)
   */
  static rgbArrayToRgbString(arr) {
    return `rgb(${arr.join(',')})`;
  }

  /**
   *
   * @param String        inputColor
   * @return String      a color string either rgb(255,255,255) (white) or rgb(0,0,0) (black) that is a contrast to the inputColor
   */
  static getContrastForColor(inputColor) {
    let rgbArray;
    if (ColorService.isHex(inputColor)) {
      rgbArray = ColorService.hexToRgb(inputColor);
    } else if (ColorService.isRgb(inputColor)) {
      rgbArray = ColorService.rgbStringToRgbArray(inputColor);
    } else {
      rgbArray = ColorService.brandColorAsArray('White');
    }
    const textColorRgb = ColorService.contrastingTextFromRgb.apply(null, rgbArray);
    return ColorService.rgbArrayToRgbString(textColorRgb);
  }


  /**
   *
   * @param String hex a 7 character hexidecimal number starting with a #
   * @return Array a representation of RGB, defaults to black if hex is invalid
   */
  static hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16)
    ] : ColorService.brandColorAsArray('Black');
  }

  /**
   *
   * @param Number hue
   * @param Number saturation
   * @param Number lightness
   * @return Array black or white in RGB
   */
  static contrastingTextFromHsl(hue, saturation, lightness) {
    if (lightness === 1) {
      return ColorService.brandColorAsArray('Black');
    } else if (lightness === 0) {
      return ColorService.brandColorAsArray('White');
    } else {
      return lightness > .05 ? ColorService.brandColorAsArray('Black') : ColorService.brandColorAsArray('White');
    }
  }

  /**
   *
   * @param Number r    The red color value
   * @param Number g    The green color value
   * @param Number b    The blue color value
   * @return Array      The contrasting color in rgb, either black or white
   */
  static contrastingTextFromRgb(r, g, b) {
    // calculate contrast of color (standard grayscale algorithmic formula)
    const contrast = (Math.round(r * 299) + Math.round(g * 587) + Math.round(b * 114)) / 1000;
    return (contrast >= 128) ? ColorService.brandColorAsArray('Black') : ColorService.brandColorAsArray('White');
  }

  /**
   * Converts an RGB color value to HSL. Conversion formula
   * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
   * Assumes r, g, and b are contained in the set [0, 255] and
   * returns h, s, and l in the set [0, 1].
   *
   * @param   Number  r       The red color value
   * @param   Number  g       The green color value
   * @param   Number  b       The blue color value
   * @return  Array           The HSL representation
   */
  static rgbToHsl(r, g, b) {
    r /= 255, g /= 255, b /= 255;

    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s;
    const l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }

      h /= 6;
    }

    return [h, s, l];
  }

  /**
   * Converts an HSL color value to RGB. Conversion formula
   * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
   * Assumes h, s, and l are contained in the set [0, 1] and
   * returns r, g, and b in the set [0, 255].
   *
   * @param   Number  h       The hue
   * @param   Number  s       The saturation
   * @param   Number  l       The lightness
   * @return  Array           The RGB representation
   */
  static hslToRgb(h, s, l) {
    let r, g, b;

    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;

      r = ColorService.hue2rgb(p, q, h + 1 / 3);
      g = ColorService.hue2rgb(p, q, h);
      b = ColorService.hue2rgb(p, q, h - 1 / 3);
    }

    return [r * 255, g * 255, b * 255];
  }

  /**
   * This method is a bit of a black box
   * @param Number p  a luminence and saturation calculation
   * @param Number q  a luminence and saturation calculation
   * @param Number t  the hue (color)
   * @return Number   represents the value of the R, G, or B channel
   */
  static hue2rgb(p, q, t) {
    if (t < 0) { t += 1; }
    if (t > 1) { t -= 1; }
    if (t < 1 / 6) { return p + (q - p) * 6 * t; }
    if (t < 1 / 2) { return q; }
    if (t < 2 / 3) { return p + (q - p) * (2 / 3 - t) * 6; }
    return p;
  }

  /**
   * Converts an RGB color value to HSV. Conversion formula
   * adapted from http://en.wikipedia.org/wiki/HSV_color_space.
   * Assumes r, g, and b are contained in the set [0, 255] and
   * returns h, s, and v in the set [0, 1].
   *
   * @param   Number  r       The red color value
   * @param   Number  g       The green color value
   * @param   Number  b       The blue color value
   * @return  Array           The HSV representation
   */
  static rgbToHsv(r, g, b) {
    r /= 255, g /= 255, b /= 255;

    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s;
    const v = max;
    const d = max - min;
    s = max === 0 ? 0 : d / max;

    if (max === min) {
      h = 0; // achromatic
    } else {
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }

      h /= 6;
    }

    return [h, s, v];
  }

  /**
   * Converts an HSV color value to RGB. Conversion formula
   * adapted from http://en.wikipedia.org/wiki/HSV_color_space.
   * Assumes h, s, and v are contained in the set [0, 1] and
   * returns r, g, and b in the set [0, 255].
   *
   * @param   Number  h       The hue
   * @param   Number  s       The saturation
   * @param   Number  v       The value
   * @return  Array           The RGB representation
   */
  static hsvToRgb(h, s, v) {
    let r, g, b;

    const i = Math.floor(h * 6);
    const f = h * 6 - i;
    const p = v * (1 - s);
    const q = v * (1 - f * s);
    const t = v * (1 - (1 - f) * s);

    switch (i % 6) {
      case 0:
        r = v, g = t, b = p;
        break;
      case 1:
        r = q, g = v, b = p;
        break;
      case 2:
        r = p, g = v, b = t;
        break;
      case 3:
        r = p, g = q, b = v;
        break;
      case 4:
        r = t, g = p, b = v;
        break;
      case 5:
        r = v, g = p, b = q;
        break;
    }

    return [r * 255, g * 255, b * 255];
  }
}
