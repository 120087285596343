import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faAngleDown as fasAngleDown,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faChevronDown,
  faChevronUp,
  faEnvelope as fasEnvelope,
  faMobileAlt,
  faPaperPlane,
  faPhone,
  faQuoteLeft,
  faTimes as fasTimes,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { faLinkedin as fabLinkedin } from '@fortawesome/free-brands-svg-icons';
import {
  faAngleDown as falAngleDown,
  faAngleLeft as falAngleLeft,
  faAngleRight as falAngleRight,
  faBars as falBars,
  faEnvelope as falEnvelope,
  faSearch as falSearch,
  faTimes as falTimes,
  faTimesCircle as falTimesCircle,
} from '@fortawesome/pro-light-svg-icons';
import { faEnvelope as farEnvelope } from '@fortawesome/free-regular-svg-icons';

export const SHARED_FONTAWESOME_ICONS = [
  faUser,
  faPhone,
  faArrowLeft,
  faArrowRight,
  faMobileAlt,
  faAngleRight,
  faChevronDown,
  faChevronUp,
  faQuoteLeft,
  faPaperPlane,
  fabLinkedin,
  falAngleDown,
  falAngleLeft,
  falAngleRight,
  falBars,
  falEnvelope,
  falSearch,
  falTimes,
  falTimesCircle,
  farEnvelope,
  fasAngleDown,
  fasEnvelope,
  fasTimes,
];

@NgModule({
  imports: [CommonModule, FontAwesomeModule],
  exports: [FontAwesomeModule],
})
export class SharedFontAwesomeModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(...SHARED_FONTAWESOME_ICONS);
  }
}
