<div *ngIf="showSearch()" class="search-container d-flex align-items-center">
  <label for="search-input">
    <fa-icon [icon]="['fal', 'search']" aria-hidden="true"></fa-icon>

    <span class="sr-only">{{ 'quicksearch.placeholder' | i18n : 'usanadotcom' }}</span>
  </label>

  <input
    id="search-input"
    class="form-control input-lg"
    placeholder="{{ 'quicksearch.placeholder' | i18n : 'usanadotcom' }}"
    autocomplete="off"
    spellcheck="false"
    autocorrect="off"
    tabindex="0"
    name="search-string"
    [(ngModel)]="searchStr"
    (ngModelChange)="search()"
  />
  <a id="search-clear" class="hide" aria-hidden="true" (click)="clearSearch()">
    <fa-icon [icon]="['fal', 'times-circle']"></fa-icon>

    <span class="sr-only">{{ 'quicksearch.clear' | i18n : 'usanadotcom' }}</span>
  </a>
</div>
<div *ngIf="data?.length || noResults" class="top-arrow"></div>
<div class="search-results-container" (uClickOutside)="clearSearch()">
  <div class="search-results">
    <div *ngIf="noResults && searchStr" class="media" style="width: 100%">
      <div class="media-body" style="width: 100%">
        <div class="no-results-text" [innerHTML]="'quicksearch.no.results' | i18n : 'usanadotcom'"></div>
        <div *ngIf="data.length > 0">
          <br />
          <hr />
          <br />
          <div [innerHTML]="cart.recommended.products"></div>
        </div>
      </div>
    </div>
    <a *ngFor="let item of data" class="media col" href="{{ getProductUrl(item.usanaId) }}" target="_blank">
      <div>
        <img src="{{ item.productImages[0].imagePath }}" alt="product image" />
      </div>

      <div class="media-body">
        <div [innerHTML]="item.productDescription.name"></div>
        <div class="muted">
          <small>{{ 'quicksearch.price' | i18n : 'usanadotcom' }} {{ item.price.priceDisplay }}</small>
        </div>
      </div>
    </a>
  </div>
</div>
<div class="search-results-loading" *ngIf="searchLoading">
  <u-loading></u-loading>
</div>
