import { NgModule } from '@angular/core';
import { NoPreloading, Route, RouterModule } from '@angular/router';
import { LocaleService } from '@shared/lib/services/locale.service';
import { combineAppAndSharedRoutes } from '@shared/lib/shared.routes';

const USANA_ROUTES: Route[] = [
  {
    path: 'home',
    loadChildren: () => import('./lazy-modules/home/home.module').then((m) => m.HomeModule),
    data: {
      preload: true,
    },
  },
  {
    path: 'lifestyle',
    loadChildren: () => import('./lazy-modules/lifestyle/lifestyle.module').then((m) => m.LifestyleModule),
    data: {
      preload: true,
    },
  },
  {
    path: 'give-back',
    loadChildren: () => import('./lazy-modules/give-back/give-back.module').then((m) => m.GiveBackModule),
    data: {
      preload: true,
    },
  },
  {
    path: 'science',
    loadChildren: () => import('./lazy-modules/science/science.module').then((m) => m.ScienceModule),
    data: {
      preload: true,
    },
  },
  {
    path: 'refund-and-return-policy',
    loadChildren: () => import('./lazy-modules/return-policy/return-policy.module').then((m) => m.ReturnPolicyModule),
  },
  {
    path: 'contact',
    loadChildren: () => import('./lazy-modules/contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'site-map',
    loadChildren: () => import('./lazy-modules/site-map/site-map.module').then((m) => m.SiteMapModule),
  },
  {
    path: 'page',
    loadChildren: () => import('./lazy-modules/generic-page/generic-page.module').then((m) => m.GenericPageModule),
  },
  {
    path: 'document',
    loadChildren: () => import('./lazy-modules/document/document.module').then((m) => m.DocumentModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(combineAppAndSharedRoutes(USANA_ROUTES, []), {
      preloadingStrategy: NoPreloading,
    }),
  ],
  exports: [RouterModule],
  providers: [LocaleService],
})
export class UsanaRoutingModule {}
