<a class="ot-btn-anchor" target="_blank" href="{{'ccpa.formurl' | i18n:'usanadotcom'}}" attr.data-automation="{{urlPathId('ccpa.formurl' | i18n:'usanadotcom')}}">
  <button type="button" class="ot-dont-sell-button ot-dont-sell-button-light">
    <img src="https://cdn.onetrust.com/images/no-sell-data-icon.svg" alt="">
    <span class="ot-text-container" [innerHTML]="'ccpa.donotsell' | i18n:'usanadotcom'"><br/>
      <span class="ot-subtext">
        <span class="ot-powered-by-text">{{'ccpa.poweredby' | i18n:'usanadotcom'}}</span>
        <span class="onetrust-text">OneTrust</span>
      </span>
    </span>
  </button>
</a>