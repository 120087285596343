import { Component } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { SideModalService } from '@shared/lib/services/side-modal.service';
import { RecaptchaExecutionResponse, UsanaReCaptchaService } from '@usana/ux/recaptcha';
import { Apollo } from 'apollo-angular';
import { Subject } from 'rxjs';
import { GRAPH_QUERY } from '../../graph/graph';
import { AssociateSearchResult } from '../../models/associate-search-result';
import { AssociateSearchArgs } from './associate-search/associate-search.component';

@Component({
  selector: 'u-dc-u-associate-search-modal',
  templateUrl: './associate-search-modal.component.html',
})
export class AssociateSearchModalComponent {
  errorMessage: string;

  searchResult$ = new Subject<ApolloQueryResult<AssociateSearchResult>>();

  constructor(
    private sideModalService: SideModalService,
    private readonly recaptchaService: UsanaReCaptchaService,
    private readonly apollo: Apollo
  ) {}

  onBackClicked(): void {
    this.searchResult$.next();
  }

  onCloseClicked(): void {
    this.sideModalService.close();
  }

  onAssociateSearchClicked(associateSearchArgs: AssociateSearchArgs): void {
    this.searchResult$.next();

    this.recaptchaService.execute('associateSearchIndia').subscribe((captchaResponse: RecaptchaExecutionResponse) => {
      if (captchaResponse.success) {
        this.searchAssociate(
          associateSearchArgs.customerId,
          associateSearchArgs.firstName,
          associateSearchArgs.lastName,
          captchaResponse.token
        );
      }
    });
  }

  private searchAssociate(customerId: string, firstName: string, lastName: string, captcha: string): void {
    this.apollo
      .query<AssociateSearchResult>({
        query: GRAPH_QUERY.GET_ASSOCIATE_LOOKUP,
        variables: {
          customerId,
          firstName,
          lastName,
          captcha,
        },
      })
      .subscribe((result) => this.searchResult$.next(result));
  }
}
