import { Component, ElementRef, Input } from '@angular/core';
import { BaseComponent } from '../base.component';
import { HttpClient } from '@angular/common/http';
import { Title, Meta } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@shared/lib/services/auth.service';
import { DataService } from '@shared/lib/services/data.service';
import { LocaleService } from '@shared/lib/services/locale.service';
import { TranslationMapperService } from '@shared/lib/services/translation-mapper.service';
import { UrlService } from '@shared/lib/services/url.service';

@Component({
  selector: 'u-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent extends BaseComponent {
  @Input() src;
  map = {
    heading: 'heading',
    text: 'text',
  };
  // BEGIN-NOSCAN
  constructor(
    protected http: HttpClient,
    localeService: LocaleService,
    titleService: Title,
    metaService: Meta,
    private modalService: NgbModal,
    urlService: UrlService,
    translationMapperService: TranslationMapperService,
    protected authService: AuthService,
    el: ElementRef,
    dataService: DataService
  ) {
    super(http, localeService, titleService, metaService, urlService, translationMapperService, el, dataService);
  }
  // END-NOSCAN
  close() {
    this.modalService.dismissAll();
  }
}
