import {Injectable, Inject} from '@angular/core';
import {SUPPORTED_MARKETS} from '@shared/lib/shared.tokens';
import {MarketLanguageSelection} from '@shared/lib/model/market-language-selection';
import {DotcomMarket} from '@shared/lib/model/dotcom-market';
import { LocaleService } from './locale.service';

@Injectable()
export class MarketService {
  // this can be removed once we have a market picker in the market module

  constructor(@Inject(SUPPORTED_MARKETS) readonly markets: DotcomMarket[], private localeService: LocaleService) {
    // remove any markets that have not been marked "Active" on this environment
    // (this allows us to test markets on enviroments that aren't released on production)
    markets.forEach((market, index) => {
      market.languages.forEach((lang, langIndex) => {
        // remove locales that aren't active
        if (!this.localeService.isAllowedMarketId(lang.locale)) {
          market.languages.splice(langIndex, 1);
        }
        // if there are no languages to choose from, remove the whole market
        if (market.languages.length < 1) {
          markets.splice(index, 1);
        }
      });
      return markets;
    });

  }


  marketFromLocale(locale): MarketLanguageSelection {
    let mls: MarketLanguageSelection = null;
    this.markets.filter((market) => {
      let idx = 0;
      market.languages.filter((loc) => {
        if (locale === loc.locale) {
          mls = new MarketLanguageSelection(market, idx);
        }
        idx++;
      });
    });
    return mls;
  }
}






