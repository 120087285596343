import { Component } from '@angular/core';
import { BaseComponent } from '@shared/lib/components/base.component';

@Component({
  selector: 'u-menu-mobile-toggle',
  templateUrl: './menu-mobile-toggle.component.html',
  styleUrls: ['./menu-mobile-toggle.component.scss']
})
export class MenuMobileToggleComponent extends BaseComponent {
  menuIsOpen = false;

  closeMobileMenu() {
    this.menuIsOpen = false;
    document.body.classList.remove('blocked-by-mobile-menu');
  }

  toggleMobileMenu() {
    this.menuIsOpen = !this.menuIsOpen;
    document.body.classList.toggle('blocked-by-mobile-menu');
  }
}
