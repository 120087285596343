import {Component, OnInit, OnDestroy} from '@angular/core';
import {LocaleService} from '@shared/lib/services/locale.service';
import {TranslationEditingService} from '@usana/ux/translations';
import {PageStructureService} from '@shared/lib/services/page-structure.service';
import {TranslationMapperService} from '@shared/lib/services/translation-mapper.service';
import {SharedDotcomConstants} from '@shared/lib/shared-dotcom.constants';
import {UsanaMarketService} from '@usana/ux/markets';
import {Subscription} from 'rxjs';
import {PageStructure} from '@shared/lib/model/page-structure';
import {ComponentTranslationMapping} from '@shared/lib/model/component-translation-mapping';

@Component({
  selector: 'u-toolbox-translations-component',
  templateUrl: './toolbox-translations.component.html',
  styleUrls: ['./toolbox-translations.component.scss']
})
export class ToolboxTranslationsComponent implements OnInit, OnDestroy {

  pageStructure: PageStructure;

  selectedPageItem: ComponentTranslationMapping;

  private pageStructureSub: Subscription;


  constructor(private translationEditingService: TranslationEditingService,
              private translationMapperService: TranslationMapperService,
              private pageStructureService: PageStructureService,
              private usanaMarketService: UsanaMarketService,
              private localeService: LocaleService) {
  }

  ngOnInit(): void {
    this.pageStructureService.getPageStructureSubject().subscribe((pageStructure: PageStructure) => {
      this.pageStructure = pageStructure;
    });
  }

  ngOnDestroy(): void {
    if (this.pageStructureSub) {
      this.pageStructureSub.unsubscribe();
      delete this.pageStructureSub;
    }
  }

  forceRefresh(dump: boolean): void {
    this.translationEditingService.reloadDirty(SharedDotcomConstants.TRANSLATION_NAMESPACE, dump)
      .then(() => {
        this.localeService.forcePushLocaleSubject();
      });
  }

  goToSelectedItem(): void {
    if (this.selectedPageItem) {
      this.goTo(this.translationMapperService.mapToKeys(this.selectedPageItem.src, this.selectedPageItem.map));
    }
  }

  goToHeader(): void {
    const ctm: ComponentTranslationMapping = this.pageStructure.header;

    this.goTo(this.translationMapperService.mapToKeys(ctm.src, ctm.map));
  }

  goToFooter(): void {
    const ctm: ComponentTranslationMapping = this.pageStructure.footer;

    this.goTo(this.translationMapperService.mapToKeys(ctm.src, ctm.map));
  }

  goToPageTags(): void {
    const ctm: ComponentTranslationMapping = this.pageStructure.pageTranslations;

    this.goTo(this.translationMapperService.mapToKeys(ctm.src, ctm.map));
  }

  private goTo(mappedTags: string[]): void {
    this.translationEditingService.getDeepLinkUrl(SharedDotcomConstants.TRANSLATION_NAMESPACE,
      // using unsafe because there is no way this hasn't been setup at this point.
      this.usanaMarketService.unsafeGetI18nInfo().current.currentMarketId, mappedTags)
      .then((url: string) => {
        window.open(url, '_blank');
      });
  }
}
