import {Injectable} from '@angular/core';
import {LazyScript} from '@shared/lib/model/lazy-script';
import {LazyPromiseWrapper} from '@shared/lib/model/lazy-promise-wrapper';

@Injectable()
export class LazyLoaderService {

  private loadedScripts: { [scriptKey: string]: LazyPromiseWrapper<any> };

  constructor() {
    this.loadedScripts = {};
  }

  lazyLoadScript(script: LazyScript): Promise<any> {
    if (!!this.loadedScripts[script.key]) {
      if (!!this.loadedScripts[script.key].response) {
        // requested and finished
        return new Promise<any>((resolve) => {
          resolve(this.loadedScripts[script.key].response);
        });
      } else {
        // requested but not finished
        return this.loadedScripts[script.key].promise;
      }
    } else {
      // hasn't been requested yet
      return this.doLazyLoad(script);
    }
  }

  private doLazyLoad(script: LazyScript): Promise<any> {
    return new Promise<any>((resolve) => {
      const htmlScript: HTMLScriptElement = document.createElement('script');
      htmlScript.src = script.src;
      htmlScript.type = 'text/javascript';
      htmlScript.async = true;
      for (const attr of script.extraAttrs) {
        htmlScript.setAttribute(attr.attrName, attr.attrValue);
      }
      htmlScript.onload = (event) => {
        setTimeout(() => {
          resolve(true);
        }, script.waitBeforeRet); // we give it a little time for the script to bootstrap itself
      };
      document.body.appendChild(htmlScript);
    });
  }
}
