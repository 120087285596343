import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, ResolveStart, Router, RouterEvent } from '@angular/router';
import { TranslationService } from '@usana/ux/translations';
import { LocaleService } from '@shared/lib/services/locale.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'u-app-base',
  template: '',
  styleUrls: [],
})
export class BaseAppComponent implements OnInit {
  @ViewChild('main', { static: true }) main: ElementRef;
  showhide = 'show-sticky-menu';

  private readonly styleId: string = 'usanadotcomStyles';

  constructor(
    private router: Router,
    private localeService: LocaleService,
    private translationService: TranslationService,
    private renderer: Renderer2
  ) {
    router.events.pipe(filter((e: any) => e instanceof ResolveStart)).subscribe((e: RouterEvent) => {
      const origUrl = decodeURIComponent(e.url);
      const url = origUrl.replace(/^(?:\/#)?#?!?/, '');

      if (origUrl !== url) {
        router.navigateByUrl(url);
      }
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
      this.closeMobileMenu();
    });

    this.localeService.getLocaleObservable().subscribe((locale) => {
      this.resetCustomStyle();
    });
  }

  @HostListener('window:click', ['$event'])
  onClick(e: any) {
    // get the dom path of the item clicked
    const path = e.composedPath() as Array<any>;
    // find any up tree anchor tag

    const theAnchor = path.find((p) => typeof p.tagName === 'string' && p.tagName.toLowerCase() === 'a');
    if (theAnchor) {
      const href: string = theAnchor.getAttribute('href');
      const target = theAnchor.getAttribute('target');

      if (target === '_blank' || (!!href && href.toLowerCase().startsWith('javascript:'))) {
        // if new tab or a gross javascript link ignore
        return;
      }
      // if it doesn't start with one of the following we assume its an external url:
      // 1. http(s)://
      // 2. /
      // 3. ../
      const regex = /^(?:\/#|(?!(?:[\/]|https?:\/\/|\.\.\/))).*/;
      if (href !== null && href.match(regex)) {
        e.preventDefault();
        this.router.navigateByUrl(href);
      }
    }
  }

  @HostListener('window:scroll', ['$event'])
  hideStickyOnScroll = () => {
    if (window.scrollY !== 0) {
      this.showhide = 'hide-sticky-menu';
    } else {
      this.showhide = 'show-sticky-menu';
    }
  };

  skipToMain() {
    this.main.nativeElement.focus();
  }

  closeMobileMenu() {
    const toggle = document.getElementsByTagName('u-menu-mobile-toggle')[0].firstElementChild;
    const toggle2 = toggle.getElementsByTagName('u-menu-mobile')[0];
    toggle.setAttribute('menu-open', 'false');
    toggle2.setAttribute('menu-open', 'false');
    document.body.classList.remove('blocked-by-mobile-menu');
  }

  private resetCustomStyle() {
    const existing = document.getElementById(this.styleId);
    // if we have a style remove it
    if (existing) {
      existing.remove();
    }

    this.translationService.translateAsPromise('usanadotcom', 'styles').then((css: string) => {
      // if we got a style add it
      if (css !== undefined && css.length > 0) {
        const head = document.getElementsByTagName('head')[0];
        const style = document.createElement('style');
        style['type'] = 'text/css'; // eslint-disable-line
        style.id = this.styleId;
        style.appendChild(document.createTextNode(css));
        head.appendChild(style);
      }
    });
  }
}
